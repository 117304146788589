
/*  ---------------------------------------------------
	APP TYPO
	---------------------------------------------------  */

	h1, h2, h3, h4, h5, h6 {
		font-weight: 400;
	}
	
	h1 {
		font-size: 30px;
		line-height: 36px;
		margin: 0 0 50px;
	}
	h2 {
		font-size: 26px;
		line-height: 32px;
		margin: 0 0 16px;
	}
	h3 {
		font-size: 20px;
		line-height: 26px;
		margin: 0 0 10px;
	}
	h4 {
		font-size: 18px;
		line-height: 24px;
		margin: 0 0 10px;
	}
	h5 {
		font-size: 16px;
		line-height: 22px;
		margin: 0 0 10px;
	}
	h6 {
		font-size: 15px;
		line-height: 23px;
		margin: 0 0 10px;
	}
	
	hr {
		margin: 0 0 40px;
		border: none;
		height: 1px;
		background: $outline;
	}
	
	caption {
		font-weight: 600;
		display: block;
		text-align: left;
		margin: 0 0 20px;
	}
	
	.link {
		position: relative;
		color: $primary;
		
		&:hover {
			&:after {
				width: 100%;
			}
		}
		
		&:after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			height: 1px;
			background: $primary;
			width: 0;
			
			@include transition(.6s ease width);
		}
	}
	
	p {
		color: $secondary-light;
		font-size: 14px;
		line-height: 24px;
		margin: 0 0 20px;
		
		&:last-child {
			margin: 0;
		}
		
		small {
			font-weight: 300;
			font-size: 14px;
		}
		
		strong, b {
			font-weight: 500;
		}
		
		a {
			color: $primary;
		}
	}

	.href {
		display: inline-block;
		cursor: pointer;
	}
	
	@include respond-to('1680') {
		h1 {
			font-size: 26px;
			line-height: 36px;
			margin: 0 0 30px;
		}
		h2 {
			font-size: 22px;
			line-height: 32px;
		}
		h3 {
			font-size: 18px;
			line-height: 28px;
		}
		h4 {
			font-size: 16px;
			line-height: 26px;
		}
		h5 {
			font-size: 15px;
			line-height: 25px;
		}
		h6 {
			font-size: 14px;
			line-height: 24px;
		}
		p {
			
		}
	}
	
	@include respond-to('1420') {
		h1 {
			font-size: 22px;
			line-height: 32px;
		}
		h2 {
			font-size: 20px;
			line-height: 30px;
		}
		h3 {
			font-size: 18px;
			line-height: 28px;
		}
		h4 {
			font-size: 16px;
			line-height: 26px;
		}
		h5 {
			font-size: 15px;
			line-height: 25px;
		}
		h6 {
			font-size: 14px;
			line-height: 24px;
		}
		p {
			
		}
	}
	
	@include respond-to('991') {
		h1 {
			font-size: 20px;
			line-height: 30px;
		}
		h2 {
			font-size: 18px;
			line-height: 28px;
		}
		h3 {
			font-size: 16px;
			line-height: 18px;
		}
		h4 {
			font-size: 15px;
			line-height: 25px;
		}
		h5 {
			font-size: 14px;
			line-height: 24px;
		}
		h6 {
			font-size: 13px;
			line-height: 23px;
		}
		p {
			font-size: 13px;
			line-height: 23px;
		}
	}