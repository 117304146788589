
/*  ---------------------------------------------------
	COMPONENTS
	---------------------------------------------------  */
	
	.simplebar-track {
		right: 0;

		&.simplebar-vertical {
			width: 4px;
		}

		.simplebar-scrollbar {

			.simplebar-visible {
				&:before {
					opacity: 1 !important;
				}
			}

			&:before {
				background: $primary;
				width: 100%;
				@include border-radius(0);
			}
		}
	}

	.simplebar-scrollbar {

		&.simplebar-visible {
			&:before {
				opacity: 1 !important;
			}
		}
	}

	/* --- 404 --- */

		#e404 {
			display: table;
			width: 100%;
			height: 100%;

			.wrapper {
				display: table-cell;
				height: calc(100vh - 120px);
				vertical-align: middle;
				text-align: center;
			}

			h1 {
				font-size: 80px;
				line-height: 90px;
				margin: 0 0 20px;
				font-weight: 900;
			}

			p {
				color: $primary-dark;
				font-size: 18px;
				line-height: 24px;
				margin: 0 0 30px;
			}

			.bttn {
				margin: 0 auto;
			}

			@include respond-to('1420') {
				h1 {
					font-size: 60px;
					line-height: 70px;
					margin: 0 0 10px;
				}
	
				p {
					font-size: 16px;
					line-height: 22px;
				}
			}
		}

	/* --- TOUR --- */
	
		.reactour__helper {
			padding: 30px 0px 10px 20px !important;
			color: $secondary-light !important;
			@include border-radius(6px !important);

			&.reactour__helper--is-open {

			}

			> div {
				overflow: hidden;
				max-height: 70vh;
				overflow-y: scroll;
				padding-right: 10px;
			}

			.kVEayH {
				width: 30px;
				height: 30px;
				text-align: center;
				line-height: 30px;
				padding: 0;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Muli', sans-serif;
			}

			.notification {
				margin: 0 0 10px;
				
				.notification-content {
					font-size: 13px;
					line-height: 17px;
					padding: 12px 16px;
					margin: 0;
				}
				
				.la {
					display: none;
				}
			}
			
			.reactour__close {
				top: 10px;
				right: 10px;
				width: 12px;
				height: 12px;
			}

			hr {
				margin: 0 0 15px;
			}

			p, ul, ol {
				font-size: 14px;
				line-height: 22px;
				margin: 0 0 10px;
				color: $secondary-dark;
			}

			p {
				&:last-child {
					margin: 0;
				}
			}
			
			ol {
				counter-reset: ol; 
				padding: 0;
				color: $secondary-light;

				> li {
					counter-increment: ol;
					position: relative;
					padding-left: 16px;
					margin: 0 0 10px;

					&:last-child {
						margin: 0;
					}

					&:before {
						content: counter(ol) ". ";
						position: absolute;
						left: 0;
						top: 5px;
						line-height: 10px;
						color: $primary;
						font-size: 12px;
						font-weight: 600;
					}
				}
			}

			ul {
				padding: 0;
				list-style: none;
				color: $secondary-light;

				> li {
					position: relative;
					padding-left: 16px;
					margin: 0 0 10px;

					&:last-child {
						margin: 0;
					}

					&:before {
						content: '●';
						position: absolute;
						left: 0;
						top: 6px;
						line-height: 10px;
						color: $primary;
						font-size: 10px;
					}
				}
			}
			
			.bttn {
				margin: 0 auto;
			}

			h1,
			h2,
			h3 {
				font-size: 18px;
				line-height: 24px;
				margin: 0 0 15px;
				color: $primary-dark;
			}

			.jrBYqH {
				display: table;
				width: 100%;
				table-layout: fixed;
				text-align: right;
				margin: 15px 0 0 0;
				padding: 0 20px 0 0;
				overflow: visible;
				max-height: 100%;

				button {
					display: inline-block;
					width: 35px;
					height: 35px;
					vertical-align: middle;
					margin: 0;
					svg {
						width: 20px;
					}

					&:first-child {
						text-align: left;
					}
					&:last-child {
						text-align: right;
					}

					&.sc-htpNat {

					}
				}
			}
		}

	/* --- FEEDBACK --- */

		#feedback {
			z-index: 1000;
			position: fixed;
			bottom: 20px;
			right: 20px;

			&.active {
				.feedbackForm {
					display: block;
				}
			}

			.feedbackToggle {
				width: 60px;
				height: 60px;
				line-height: 60px;
				text-align: center;
				font-size: 30px;
				border: none;
				margin: 0;
				cursor: pointer;
				color: $primary;
				background: $outline;
				@include border-radius(50%);

				@include transition(.6s ease color, .6s ease background);

				&:hover {
					background: $primary;
					color: #fff;
				}
			}

			.feedbackForm {
				background: #fff;
				@include border-radius(4px);
				width: 300px;
				position: absolute;
				right: 0;
				bottom: 0;
				padding: 20px;
				box-shadow: rgba(0,0,0,.1) 0 0 20px;
				overflow: hidden;
				display: none;

				.email-sent {
					font-size: 16px;
					line-height: 22px;
					font-weight: 600;
					text-align: center;
					display: table;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
					background: #fff;
					z-index: 1;
					display: none;
					
					> .wrapper {
						display: table-cell;
						vertical-align: middle;
						padding: 20px;
					}
				}

				.input-row {
					margin: 0 0 15px;

					[type="text"],
					[type="email"] {
						padding: 0 10px;
						height: 44px;
						font-size: 13px;
						font-weight: 600;
						letter-spacing: 0.3px;
					}

					textarea {
						padding: 10px;
						height: 130px;
						resize: none;
						font-size: 13px;
						font-weight: 600;
						letter-spacing: 0.3px;
					}

					&:last-child {
						margin: 0;
					}
				}

				.form-head {
					padding: 0 0 20px;

					.form-title {
						font-size: 18px;
						font-weight: 600;
						line-height: 26px;
						margin: 0 0 5px;
					}
					.form-desc {

					}
				}

				.form-body {

				}

				.form-footer {
					padding: 20px 0 0 0;
					display: table;
					table-layout: fixed;
					width: 100%;
					
					> .part {
						display: table-cell;
						vertical-align: middle;
						padding: 0 10px;

						&:last-child {
							padding-right: 0;
						}

						&:first-child {
							padding-left: 0;
						}
					}
				}
			}
		}

	/* --- TIMELINE --- */
	
	    .timeline {
	        .timeline-entry {
	            display: table;
	            width: 100%;
	            margin: 0 0 20px;
	            
	            &:first-child {
	                .timeline-part {
	                    &._date {
	                        &:before {
	                            top: 50%;
	                        }
	                    }
	                }
	            }
	            &:last-child {
					margin: 0;

	                .timeline-part {
	                    &._date {
	                        &:before {
	                            top: auto;
	                            height: 50%;
	                            bottom: 50%;
	                        }
	                    }
	                }
	            }
	            
	            .timeline-part {
	                display: table-cell;
	                vertical-align: middle;
	                padding: 0 35px;
	                
	                &:first-child {
	                    padding-left: 0;
	                }
	                &:last-child {
	                    padding-right: 0;
	                }
	                
	                &._text {
	                    .wrapper {
	                        padding: 10px;
	                        background: #fff;
	                        display: table;
	                        width: 100%;
	                        
	                        @include border-radius(4px);
	                        
	                        .part {
	                            display: table-cell;
	                            width: 100%;
	                            vertical-align: middle;
	                            
	                            &._part-options {
	                                padding-left: 20px;
	                                white-space: nowrap;
	                            }
	                        }
	                    }
	                }
	                
	                &._date {
	                    width: 110px;
	                    font-weight: 700;
	                    font-size: 13px;
	                    color: $secondary-dark;
						position: relative;
						
						small {
							display: block;
							font-size: 12px;
							line-height: 16px;
							color: $secondary-light;
						}
	                    
	                    &:after {
	                        width: 16px;
	                        height: 16px;
	                        border: 2px solid $outline;
	                        content: '';
	                        position: absolute;
	                        right: -9px;
	                        top: 50%;
	                        background: $background-light;
	                        
	                        @include transform(translate(0, -50%));
	                        
	                        @include border-radius(50%);
	                    }
	                    
	                    &:before {
	                        content: '';
	                        width: 2px;
	                        height: calc(100% + 20px);
	                        position: absolute;
	                        background: $outline;
	                        top: 0;
	                        right: 0;
	                    }
	                }
	            }
	            
	            .timeline-status {
	                display: table;
	                width: 100%;
					
					p {

					}

	                .status-left {
	                    padding-right: 20px;
	                    display: table-cell;
	                    vertical-align: middle;
	                    width: 68px;
	                    .status-image {
	                        width: 48px;
                            height: 48px;
                            line-height: 48px;
                            background-color: $outline;
                            text-align: center;
                            color: $primary-dark;
                            font-weight: 700;
                            font-size: 12px;
                            text-transform: uppercase;
                            
                            @include border-radius(50%);
	                    }
	                }
	                .status-body {
	                    display: table-cell;
	                    vertical-align: middle;
	                    font-size: 14px;
	                    color: $secondary-dark;
						
						p {
							font-size: 14px;
							color: $secondary-dark;
							line-height: 18px;
							margin: 0 0 10px;

							&:last-child {
								margin: 0;
							}
						}

	                    a {
	                        color: $primary;
	                    }
	                    
	                    small {
	                        font-size: 15px;
	                        color: $secondary-dark;
	                    }
	                }
	            }
			}
			
			@include respond-to('1420') {
				.timeline-entry {
					.timeline-part {
						padding: 0 30px;
					}
				}
			}
			@include respond-to('991') {
				.timeline-entry {
					.timeline-status {
						.status-body {
							font-size: 14px;
						}
					}
				}
			}
			@include respond-to('767') {
				.timeline-entry {
					.timeline-status {
						.status-left {
							display: none;
						}
					}
				}
			}
			@include respond-to('480') {
				.timeline-entry {
					.timeline-status {
						.status-left {
							padding-right: 15px;
						}
					}
					.timeline-part {
						padding: 0;
						&._date,
						&._text {
							display: block;
						}

						&:before,
						&:after {
							display: none;
						}

						&._date {
							margin: 0 0 5px;

							small {
								display: inline-block;
							}
						}
						&._text {
							.wrapper {
								.part {
									&._part-options {
										padding-left: 10px;
									}
								}
							}
						}
					}
				}
			}
			
		}
	
	/* --- RESULTS --- */


	.answer-modal {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc( 100% - 120px );
		z-index:100;
		padding-top: 70px;
		display: block;
		
		.wrapper {
			width: 100%;
			height: 100%;
			display: table;

			.table-cell {
				vertical-align: middle;
				display: table-cell;
				text-align: center;
			}
		}
	}

	.answer-holder {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc( 100% - 110px );
		z-index: 100;
		pointer-events: none !important;

		&.__active {
			pointer-events: all !important;
		}
	}

	.question-answer {
		display: inline-block;
		padding: 20px;
		width: 100%;
		max-width: 500px;
		font-size: 18px;
		font-weight: 600;
		border: 4px solid rgba(0,0,0,.1);
		text-align: center;
		z-index: 1000;

		@include border-radius(8px);

		&._wrong {
			background: $red;
			color: #fff;
		}
		&._corrent {
			background: $green;
			color: #fff;
		}

		.lang-result {
			padding: 20px 0 0 0;
		}

		.langs-block {
			padding: 20px 0 0 0;
			display: table;
			width: 100%;
			table-layout: fixed;

			.langs-part {
				display: table-cell;
				vertical-align: middle;
				padding: 0 10px;

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}

				.lang {
					font-size: 14px;
					width: 100%;

					.flag {
						width: 32px !important;
						height: auto !important;
						margin: 0 auto;
						display: table;
						box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
						@include border-radius(3px);
					}

					span {
						color: $secondary-dark;
						margin-right: 10px;
						font-size: 12px;
						position: relative;
						text-decoration: none !important;

						&:after {
							position: absolute;
							content: '';
							background: $red;
							width: 40px;
							height: 2px;
							top: -2px;
							left: 50%;

							transform: translate(-50%, -50%);

							@include transform(rotate(-27deg) translate(-50%, -50%));
						}
					}
				}
			}
		}

		.icon {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: 64px;
			color: #000;
			opacity: .2;
		}

		@include respond-to('1420') {
			font-size: 16px;

			.lang-result {
				padding: 10px 0 0 0;
			}
		}
		@include respond-to('991') {
			.langs-block {
				.langs-part {
					display: block;
					padding: 0;
					margin: 0 0 10px;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
		
	}

	.question-results {
		margin: 30px 0;

		.question-result {
			margin: 0 0 30px;
			padding: 0 0 30px;
			border-bottom: 1px dashed #dadada;
			padding-left: 40px;
			position: relative;

			&:first-of-type {
				margin-top: 20px;
			}

			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border: none;
			}

			.order {
				position: absolute;
				left: 0;
				top: 11px;
				font-weight: 600;
				font-size: 20px;
				color: $secondary-light;
			}
			.lang-result {
				&._valid {
					.lang {
						border-color: $green;
						.part {
							
						}
					}
				}
				&._invalid {
					.lang {
						border-color: $red;
						.part {
							
						}
					}
				}

				.langs-block {
					display: table;
					width: 100%;
					table-layout: fixed;

					.langs-part {
						display: table-cell;
						vertical-align: middle;
						padding: 0 10px;

						&:first-child {
							padding-left: 0;
						}
						&:last-child {
							padding-right: 0;
						}

						.lang {
							font-size: 14px;
							width: 100%;

							.flag {
								width: 32px !important;
								height: auto !important;
								margin: 0 auto;
								display: table;
								box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
								@include border-radius(3px);
							}

							span {
								color: $secondary-dark;
								margin-right: 10px;
								font-size: 12px;
								position: relative;
								text-decoration: none !important;

								&:after {
									position: absolute;
									content: '';
									background: $red;
									width: 40px;
									height: 2px;
									top: -2px;
									left: 50%;

									transform: translate(-50%, -50%);

									@include transform(rotate(-27deg) translate(-50%, -50%));
								}
							}
						}
					}
				}
			}
		}

		@include respond-to('1199') {
			.question-result {
				margin: 0 0 15px;
				padding: 0 0 15px 30px;

				.order {
					font-size: 16px;
					top: 14px;
				}

				.lang-result {
					.langs-block {
						.langs-part {
							.lang {
								font-size: 12px;
							}
						}
					}
				}
			}
		}

		@include respond-to('767') {
			.question-result {
				.lang-result {
					.langs-block {
						.langs-part {
							padding: 0;
							display: block;
							margin: 0 0 6px;

							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
	    
	/* --- CALENDAR --- */
	
		.react-datepicker-wrapper {
			width: 100%;
			display: block;
			
			.react-datepicker__input-container {
				.react-datepicker__close-icon {
					padding: 0;
					
					&:after {
						font-size: 21px !important;
						right: 15px;
						position: absolute;
						top: 13px;
						content: "\f343";
						font: normal normal normal 16px/1 LineAwesome;
						background: none;
						color: $red;
					}
				}
			}
		}
		
		.react-datepicker-popper {
			max-width: 271px;
			
			&.with-time {
				.react-datepicker {
					width: calc(100% + 80px);
					padding-right: 80px;
					
					.react-datepicker__navigation {
						
						&.react-datepicker__navigation--previous {
							right: 116px;
						}
						&.react-datepicker__navigation--next {
							right: 90px;
						}
					}
					
					.react-datepicker__month-container {
						.react-datepicker__month {
						}
					}
					.react-datepicker__time-container  {
						position: absolute;
						float: none;
						right: 0;
						top: 0;
						height: 100%;
						
						.react-datepicker__time {
							height: calc(100% - 37px);
							position: absolute;
							
							.react-datepicker__time-box {
								width: 85px;
								height: 100%;
								position: absolute;
								
								.react-datepicker__time-list {
									height: 100% !important;
								}
							}
						}
						
						
					}
				}
			}
			
			.react-datepicker {
				font-family: 'Muli', sans-serif;
				font-size: 12px;
				border: 1px solid $outline;
				font-weight: 400;
				
				@include border-radius(4px);
				
				.react-datepicker__triangle {
					&:before {
						border-top-color: $outline;
					}
					
				}
				
				.react-datepicker__navigation {
					top: 18px;
					
					&.react-datepicker__navigation--previous {
						left: auto;
						right: 36px;
					}
					&.react-datepicker__navigation--next {
						
					}
				}
				
				.react-datepicker__time-container  {
					border-left: 1px solid #E8ECEF;
					
					.react-datepicker__header--time {
						border-bottom: 1px solid #E8ECEF;
						background: none;
						
						@include border-radius(0);
						
						.react-datepicker-time__header {
							font-size: 14px;
						}
					}
					.react-datepicker__time {
						.react-datepicker__time-list {
							font-weight: 600;
						}
					}
				}
				
				.react-datepicker__month-container {
					.react-datepicker__header {
						background: none;
						border: none;
						border-bottom: 1px solid $outline;
						padding: 15px 10px 10px;
						
						.react-datepicker__current-month {
							font-size: 14px;
							text-align: left;
							margin: 0 0 10px;
							padding: 0 10px;
						}
						
						.react-datepicker__day-names {
							display: table;
							width: 100%;
							table-layout: fixed;
							
							.react-datepicker__day-name {
								display: table-cell;
								vertical-align: middle;
								margin: 0;
								font-weight: 600;
								width: inherit;
								font-size: 12px;
								color: $secondary-dark;
							}
						}
					}
					.react-datepicker__month {
						background: none;
						border: none;
						margin: 0;
						padding: 10px;
						font-size: 12px;
						font-weight: 600;
						
						.react-datepicker__week {
							display: table;
							width: 100%;
							table-layout: fixed;
							
							.react-datepicker__day {
								display: table-cell;
								vertical-align: middle;
								height: 36px;
								width: inherit;
								
								&.react-datepicker__day--outside-month {
									color: $outline;
									
									&:hover {
										color: $primary-dark;
									}
								}
								
								&.react-datepicker__day--disabled {
									color: #ccc !important;
									background: none !important;
									pointer-events: none !important;
								}
								
								&.react-datepicker__day--selected {
									color: #fff;
									background: $primary;
									@include border-radius(4px);
								}
								&.react-datepicker__day--today {
									position: relative;
									
									&:before {
										content: '';
										right: 6px;
										top: 6px;
										width: 4px;
										height: 4px;
										background: $primary;
										position: absolute;
										
										@include border-radius(50%);
									}
								}
							}
						}
					}
				}
			}
		}
	
	
	/* --- PRELOADER --- */
	
	    .preloader {
		    position: absolute;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    background: rgba(255,255,255,.8) url("../../assets/img/preloader.svg") no-repeat center center;
		    z-index: 2;
		}
	
	/* --- TABS --- */
	
	    .tabs {
	        display: table;
	        width: 100%;
	        background: #fff;
	        
	        @include border-radius(4px);
	        
	        .tabs-content,
	        .tabs-nav {
	            display: table-cell;
	            vertical-align: top;
	            position: relative;
	        }
	        
	        .tabs-nav {
	            width: 292px;
	            
	            .tabs-nav-top {
	                padding: 0 0 200px;
	                
	                .tabs-nav-list {
	                    > ul {
	                        margin: 0;
	                        padding: 0;
	                        list-style: none;
	                        
	                        > li {
	                            border-bottom: 1px solid $background;
	                            
	                            &.active {
	                                .href {
	                                    border-color: $primary;
	                                    background: #fafbfb;
	                                    
	                                    .tab-name {
	                                        color: $primary;
	                                    }
	                                }
	                            }
	                            
	                            .href {
	                                display: block;
	                                padding: 16px 58px;
	                                position: relative;
	                                border-left: 2px solid transparent;
	                                
	                                &:hover {
	                                    background: #fafbfb;
	                                    
	                                    .tab-name {
	                                        color: $primary;
	                                    }
	                                }
									
									svg {
	                                    color: $secondary-dark;
	                                    font-size: 20px;
                                        font-weight: 300;
                                        position: absolute;
                                        top: 50%;
										left: 21px;
										transform: translate(0, -50%);
									}
									
	                                .la {
	                                    color: $secondary-dark;
	                                    font-size: 20px;
                                        font-weight: 300;
                                        position: absolute;
                                        top: 50%;
										left: 21px;
										transform: translate(0, -50%);
	                                }
	                                
	                                .tab-name {
	                                    font-size: 16px;
	                                    color: $primary-dark;
	                                    font-weight: 400;
	                                    
	                                    @include transition(.6s ease color);
	                                }
	                                .tab-desc {
	                                    font-weight: 400;
	                                    font-size: 13px;
	                                    color: $secondary-light;
	                                }
	                            }
	                        }
	                    }
	                }
	            }
	            .tabs-nav-bottom {
	                padding: 0 26px 0;
	                position: absolute;
	                left: 0;
	                bottom: 0;
	                width: 100%;
	                
	                .tabs-nav-list {
	                    padding: 20px 0;
	                    border-top: 1px solid $background;
	                    ul {
	                        margin: 0;
	                        padding: 0;
	                        list-style: none;
	                        li {
	                            margin: 0 0 16px;
	                            
	                            &:last-child {
	                                margin: 0;
	                            }
	                            
	                            .href {
	                                color: $primary;
	                                position: relative;
	                                font-size: 14px;
	                                padding-left: 30px;
	                                
	                                .la {
	                                    position: absolute;
                                        left: 0;
                                        font-size: 21px;
                                        top: -1px;
	                                }
	                            }
	                        }
	                    }
	                }
	            }
	        }
	        
	        .tabs-content {
	            border-right: 1px solid $background;
	            
	            .tab {
	                padding: 20px 30px;
					
					.notification {
						margin: 0;
					}

					.pagination {
						margin-top: 20px;
					}

					.table {
						padding: 0;
						margin: 0;
					}

	                .tab-header,
	                .tab-footer {
	                    display: table;
	                    width: 100%;
	                    table-layout: fixed;
	                    
	                    > .part {
	                        padding: 0 10px;
	                        display: table-cell;
	                        vertical-align: middle;
	                        
	                        &:last-child {
	                            text-align: right;
	                            padding-right: 0;
	                        }
	                        &:first-child {
	                            padding-left: 0;
	                            text-align: left;
	                        }
	                    }
	                }
	                .tab-body {
	                    //min-height: 500px;
	                }
	                
	                .tab-footer {
	                    padding: 20px 0 0 0;
	                    margin: 20px 0 0 0;
	                    border-top: 1px solid $background;
	                }
	                
	                .tab-header {
	                    padding: 0 0 16px;
	                    margin: 0 0 20px;
	                    border-bottom: 1px solid $background;
	                    
	                    h2 {
	                        margin: 0;
	                        font-size: 19px;
	                    }
	                }
	            }
			}
			
			@include respond-to('1680') {
				.tabs-nav {
					width: 230px;

					.tabs-nav-top {
						.tabs-nav-list {
							> ul {
								> li {
									.href {
										padding: 12px 20px 12px 40px;

										.la {
											left: 8px;
										}
										svg {
											
											left: 8px;
										}
									}
								}
							}
						}
					}
				}
			}

			@include respond-to('1420') {

				.tabs-content {
					.tab {
						.tab-header {
							h2 {
								font-size: 17px;
								line-height: 24px;
							}
						}
					}
				}
			}

			@include respond-to('991') {

				.tabs-content {
					.tab {
						padding: 20px;

						.tab-header {
							h2 {
								font-size: 15px;;
							}
						}
					}
				}

				.tabs-nav {
					width: auto;

					.tabs-nav-bottom {
						padding: 0;

						.tabs-nav-list {
							padding: 0;

							ul {
								li {
									.href {
										font-size: 0;
										padding: 12px 0;
										line-height: 0;
										display: block;
										.la {
											font-size: 28px;
											display: table;
											margin: 0 auto;
											padding: 0;
											position: relative;
											top: auto;
											left: auto;
											
										}
									}
								}
							}
						}
					}

					.tabs-nav-top {
						.tabs-nav-list {
							> ul {
								> li {
									.href {
										padding: 10px;

										.tab-name,
										.tab-desc {
											display: none;
										}

										.la {
											top: auto;
											left: auto;
											position: relative;
											font-size: 28px;
											cursor: pointer;
											margin: 0 auto;
											display: table;
											color: $primary;
											transform: translate(0);
										}
										svg {
											top: auto;
											left: auto;
											position: relative;
											font-size: 24px;
											cursor: pointer;
											margin: 0 auto;
											display: table;
											color: $primary;
											transform: translate(0);
										}
									}
								}
							}
						}
					}
				}
			}
	    }
	
	/* --- MODALS --- */
	
	    .modalWrapper {
	        background: rgba(0,0,0,.6);
        	position: fixed;
        	display: block;
        	top: 0;
        	left: 0;
        	bottom: 0;
			right: 0;
			height: 100%;
        	z-index: 10001;
        	padding: 30px 30px 0;
        	backface-visibility: hidden;
        	overflow: auto;
        	vertical-align: middle;
        	text-align: center;

            &.hidden {
                display: none;
            }

        	&:after {
        		display: inline-block;
        		height: 100%;
        		content: "";
        		vertical-align: middle;
        	}
        		
        	.modal {
        		margin: 0 auto 30px;
        		background: #fff;
        		width: 100%;
        		max-width: 600px;
        		height: auto;
        		padding: 0;
        		vertical-align: middle;
        		display: inline-block;
        		text-align: left;
        		position: relative;
        		
        		@include border-radius(4px);
			}
			
			@include respond-to('480') {
				padding: 20px 10px 0;
			}
		}
		
		.close {
			&.-wrapper {
				position: fixed;
				top: 15px;
				right: 30px;
				z-index: 10;
				font-size: 46px;
				color: #fff;
				cursor: pointer;

				@include respond-to('1420') {
					font-size: 36px;
				}

				@include respond-to('991') {
					display: none;
				}
			}
		}
	
	    .modal-window {
	        position: relative;
	        
	        &._alt {
	            .modal-window-body {
	                background: none;
	                border-top: 1px solid $background-light;;
	            }
	        }
	        
	        .close {
	            position: absolute;
	            font-size: 28px;
                top: 20px;
	            right: 20px;
	            color: $secondary-dark;
	            cursor: pointer;
	            z-index: 2;
	            
	            @include transition(.6s ease color);
	            
	            &:hover {
	               color: $red; 
	            }
	        }
	        
	        .modal-window-header {
	            display: table;
	            width: 100%;
	            table-layout: fixed;
	            padding: 20px 44px 20px 24px;
	            
	            h3 {
	                margin: 0;
	            }
	            
	            .part {
	                padding: 0 10px;
	                display: table-cell;
	                vertical-align: middle;
	                
	                &:last-child {
	                    padding-right: 0;
    	                text-align: right;
    	            }
    	            &:first-child {
    	                text-align: left;
    	                padding-left: 0;
    	            }
	            }
	        }
	        .modal-window-body {
	            background: $background-light;
	            padding: 26px 24px;
	        }
	        
	        .modal-footer {
	            display: table;
                width: calc(100% + 48px);
                table-layout: fixed;
                padding: 20px 24px;
                background: #fff;
                margin: 40px -24px -24px;
	            
	            .part {
	                padding: 0 10px;
	                display: table-cell;
	                vertical-align: middle;
	                
	                .bttn {
	                    display: inline-block;
	                }
	                
	                &:last-child {
	                    padding-right: 0;
    	                text-align: right;
    	            }
    	            &:first-child {
    	                text-align: left;
    	                padding-left: 0;
    	            }
	            }
	        }
	        
	        .modal-window-footer {
	            display: table;
	            width: 100%;
	            table-layout: fixed;
	            padding: 20px 24px;
	            
	            .part {
	                padding: 0 10px;
	                display: table-cell;
	                vertical-align: middle;
	                
	                .bttn {
	                    display: inline-block;
	                }
	                
	                &:last-child {
	                    padding-right: 0;
    	                text-align: right;
    	            }
    	            &:first-child {
    	                text-align: left;
    	                padding-left: 0;
    	            }
	            }
			}

			@include respond-to('991') {
				.close {
					top: 16px;
					right: 16px;
				}
			}
			
			@include respond-to('767') {
				
                .modal-footer {
					margin: 20px -24px -24px;

					> .part {
						padding: 0;
						margin: 0 0 10px;
						display: block;	
						
						&:last-child {
							margin: 0;
						}

						.bttn {
							display: block;
							width: 100%;
						}
					}
				}
			}
			
			@include respond-to('480') {
				.modal-window-header {
					padding: 20px 44px 20px 20px;
				}
				.modal-window-body {
					padding: 20px;
				}
				.modal-window-footer {
					padding: 20px;

					> .part {
						display: block;
						padding: 0;
						margin: 0 0 10px;

						.bttn {
							width: 100%;
						}

						&:last-child {
							margin: 0;
						}
					}
				}
				.modal-footer {
					width: calc(100% + 40px);
					margin: 20px -20px -20px;
				}
			}
	    }
	    
    /* --- NOTIFICATIONS --- */
    
        .notification {
            background: $primary;
            color: #fff;
            display: block;
            font-size: 14px;
            line-height: 20px;
            position: relative;
            margin: 0 0 12px;
            
            @include border-radius(4px);
            
            &._dark {
                background: $primary-dark;
            }
            &._dark-alt {
                background: $secondary-dark;
            }
            &._dark-alt-light {
                background: $secondary-light;
            }
            &._orange {
                background: $orange;
            }
            &._green {
                background: $green;
            }
            &._red {
                background: $red;
            }
            &._blue {
                background: $blue;
            }
            &._outline {
                background: $outline;
                color: $secondary-dark;
            }
            &._background {
                background: $background;
                color: $secondary-dark;
            }
            
            &:last-child {
                margin: 0;
            }
			
			@keyframes animatedIcon {
				0% {
					transform: translate(0, -50%) scale(1.0);
				}
				100% {
					transform: translate(0, -50%) scale(1.5);
				}
			}

            .close,
            .icon {
                position: absolute;
                font-size: 22px;
                top: 50%;
                
				@include transform(translate(0, -50%));
				
				&._animated {
					animation: animatedIcon 1s ease-in-out infinite alternate;
				}
            }
            
            .close {
                right: 19px;
                cursor: pointer;
            }
            .icon {
                left: 20px;
            }
            
            .notification-content {
                padding: 10px 20px 10px 60px;
                margin: 20px 0 0 0;
			}
			
			@include respond-to('767') {
				.notification-content {
					padding: 12px;
				}

				.icon {
					display: none;
				}
			}
        }
        
        .floating-notification {
            position: fixed;
            z-index: 10000;
            top: 65px;
            right: 20px;
            width: 400px;
            
            .notification {
                box-shadow: rgba(0,0,0,.05) 0 0 20px;
                opacity: 0;
                transition: all .5s ease;

                .notification-content {
                    padding: 20px 50px 20px 60px;
                    font-size: 16px;
                }

                &.show_true {
                    opacity: 1;  
                }

                &.hide_true {
                    opacity: 0;
                }
			}
			
			@include respond-to('767') {
				left: 20px;
				right: 20px;
				width: calc(100% - 40px);

				.notification {
					.notification-content {
						padding: 14px 50px 14px 14px;
					}
				}
			}
        }
        
    /* --- FILTERS --- */
    
        .page-filter {
            display: table;
            width: 100%;
            table-layout: fixed;
			margin: 0 0 30px;
			height: 20px;
            
            > .part {
                display: table-cell;
                vertical-align: middle;
                padding: 0 10px;
                
                &:last-child {
                    padding-right: 0;
                    text-align: right;
                    
                    .filters {
                        float: right;
                    }
                }
                &:first-child {
                    padding-left: 0;
                    text-align: left;
				}
				
				&._filters {
					.filter {
						&:first-child {
							padding-left: 0;
						}
					}
				}

				&._rightpart {
					.filters {
						.filter {
							padding-left: 30px;
						}
					}
				}
            }
            
            .filters {
                display: table;
                width: auto;
                table-layout: fixed;
                
                > .filter {
					display: table-cell;
					height: 20px;
                    vertical-align: middle;
                    padding: 0 20px 0 0;
                    position: relative;
                    font-size: 12px;
                    font-weight: 700;
                    text-transform: uppercase;
                    cursor: pointer;
                    
                    &.active {
                        .la {
                            color: $primary !important;
                        }
                    }
                    &.__active {
                        .la {
                            color: $primary;
                        }

                        .filter-type {
                            
                            &.sort {
                                .sort-content {
                                    
                                    .sort-list {
                                        padding-top: 10px;
                                        opacity: 1;
                                        pointer-events: auto;
                                        z-index: 2;
                                    }
                                }   
                            }
                        }
                    }
                    
                    &:last-child {
                        padding-right: 0;
                    }
                    
                    .la:not(.la-long-arrow-right) {
                        position: absolute;
                        left: 0;
                        font-weight: 300;
                        top: 50%;
                        font-size: 22px;
                        color: $secondary-dark;
                        @include transform(translate(0, -50%));
                        
                        @include transition(.6s ease color);
                    }
                    
                    .filter-type {
                        color: $secondary-dark;
                        white-space: nowrap;
                        > * {
                            vertical-align: middle;
                        }
                        
                        &.add-new {
                            color: $primary;
							font-weight: 900;
                            
                            .la {
                                color: $primary;
                            }
                        }
                        
                        &.sort {
                            .sort-content {
                                padding: 0 30px 0 10px;
                                position: relative;
                                display: inline-block;
                                
                                >.la {
                                    position: absolute;
                                    font-size: 16px;
                                    right: 0;
                                    left: auto;
                                    top: 9px;
                                    color: $secondary-dark;
                                }
                                
                                .sort-current {
                                    color: $primary-dark;
                                    font-size: 12px;
                                    font-weight: 700;
                                }
                                
                                .sort-list {
                                    position: absolute;
                                    top: 100%;
                                    width: auto;
                                    white-space: nowrap;
                                    right: -8px;
                                    pointer-events: none;
                                    opacity: 0;
                                    margin: 0 !important;
                                    padding: 20px 0 0 0;
                                    
                                    @include transition(.3s ease padding, .3s ease opacity);
                                    
                                    >ul {
                                        margin: 0;
                                        padding: 10px 0;
                                        list-style: none;
                                        background: #fff;
                                        position: relative;
                                        box-shadow: 0 2px 16px 0 rgba(153,155,168,0.12);
                                        
                                        &:before {
                                            bottom: 100%;
                                        	right: 10px;
                                        	border: solid transparent;
                                        	content: " ";
                                        	height: 0;
                                        	width: 0;
                                        	position: absolute;
                                        	pointer-events: none;
                                        	border-color: rgba(255, 255, 255, 0);
                                        	border-bottom-color: #fff;
                                        	border-width: 6px;
                                        	margin-left: -6px;
                                        }
                                        
                                        @include border-radius(4px);
                                        
                                        >li {
                                            display: block;
                                            color: $primary-dark;
                                            font-weight: 700;
                                            text-transform: uppercase;
                                            padding: 0px 15px 10px;
                                            font-size: 10px;
                                            
                                            @include transition(.6s ease color);
                                            
                                            &:last-child {
                                                padding-bottom: 0;
                                                border: none;
                                            }
                                            
                                            &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
            
            .part-langs {
                span {
                    width: 24px !important;
                    height: 18px !important;
                    display: block !important;
                }
                
                > ul {
                    > li {
                        padding: 0 2px;
                    }
                }
                
                .la {
                    font-size: 15px;
                }
			}
			
			@include respond-to('1300') {
				margin: 0 0 20px;
			}

			@include respond-to('991') {

				> .part {
					&._rightpart {
						.filters {

							&.mobile-hidden {
								._tabletype {
									display: none;
								}
							}
						
						}
					}
				}

				
			}

			@include respond-to('767') {

				> .part {
					&._rightpart {
						.filters {

							& > .filter {
								padding: 0 0 0 10px;
								
								.filter-type {
		
									.la {
										position: relative;
										top: 0;
										@include transform(translate(0, 0));
										font-size: 28px;
										width: 28px;
									}
									span {
										display: none;
									}
								}
							}
						}
					}

					&._filters {
						.filter {
							display: block;
							margin: 0 0 10px;

							&:last-child {
								margin: 0;
							}
						}
					}
				}

				
			}
        }
        
    /* --- HEADERS --- */
    
        .page-header {
            display: table;
            width: 100%;
            margin: 0 0 30px;
            table-layout: fixed;
            
            > .part {
                display: table-cell;
                vertical-align: middle;
                padding: 0 10px;
                
                &:last-child {
                    padding-right: 0;
                    text-align: right;
                }
                &:first-child {
                    padding-left: 0;
                    text-align: left;
                }
            }
            
            .bttn {
                margin-right: 4px;
                display: inline-block;
                
                &:last-child {
                    margin: 0;
                }
            }
			
			small {
				font-size: 12px;
				display: block;
				padding: 5px 0 0 0;
				font-weight: 600;
				color: $secondary-dark;
			}

            h1 {
                margin: 0;
            }
            
            h2 {
                font-weight: 400;
                margin: 0;
                font-size: 26px;
            }
            p {
                font-size: 16px;
                line-height: 23px;
                margin: 8px 0 0 0;
                color: $secondary-light;
			}
			
			@include respond-to('1300') {
				h2 {
					font-size: 22px;
				}
				p {
					font-size: 15px;
					line-height: 22px;
				}
			}

			@include respond-to('991') {
				margin: 0 0 20px;

				> .part {
					display: block;
					padding: 0;
					margin: 0 0 20px;
					text-align: center;

					&:last-child {
						margin: 0;
						text-align: center;;
					}
				}

				h2 {
					font-size: 20px;
				}
				p {
					font-size: 14px;
					line-height: 20px;
				}
			}

			@include respond-to('480') {
				.bttn {
					margin: 0 0 10px;
					width: 100%;
					text-align: center;
					
					&:last-child {
						margin: 0;
					}
				}
			}
		}
		

body.certificate {
	.alphie-content-wrapper {
		width: 100vw;
		height: 100vh;

		iframe {
			width: 100%;
			height: 100%;
		}
	}
}

.small-widgets.--share {
	text-align: center;
}

.share {
	background: rgb(59, 89, 152);;
	color: white;
	width: auto;
	display: inline-block;
	line-height: 46px;
	font-size: 13px;
	font-weight: 700;
	border-radius: 5px;
	text-transform: uppercase;
	padding: 0 10px;

	svg {
		margin-right: 5px;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	i {
		font-size: 40px;
	}

	&.--part {
		line-height: 10px;
		margin: 0;
		display: inline-block;
		margin-right: 3px;
		padding: 0 4px;

		&.--right {
			float: right;
		}
	}
}

._part.--sharing {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
}

.bttn {
	&.--share {
		min-width: initial;
		display: inline-block;
		svg {
			width: 27px;
			height: 27px;
			margin: 0;
			padding: 0;
		}
	}
}