.fade-enter {
    opacity: 0.01;
    
    &-active {
        opacity: 1;
        
        @include transition(.3s ease-in opacity);
    }
    
}

.fade-exit {
    opacity: 1;
    
    &-active {
        opacity: .01;
        position: absolute !important;
        @include transition(.3s ease-in opacity);
    }
}

.test-block {
    position: relative;
}

.shadow-hidden {
    position: absolute;
    width: 100%;
    height: calc(100% - 100px);
    top: 0;
    z-index: 1000;
    pointer-events: all;
}

.pointer {
    cursor: pointer;
}