#mainSection {
	.mainSectionHeader {
		margin: 0 0 50px;
		display: table;
		width: 100%;
		table-layout: fixed;
		
		.part {
			display: table-cell;
			vertical-align: middle;
			padding: 0 10px;
			
			&:last-child {
				text-align: right;
				padding-right: 0;
			}
			
			&:first-child {
				padding-left: 0;
				text-align: left;
			}
			
			.buttons {
				.bttn {
					display: inline-block;
					margin-left: 10px;
				}
			}
		}
		
		h1 {
			font-weight: 500;
			margin: 0;
			letter-spacing: .5px;
			font-size: 22px;
		}
	}
}

.login-header {
	position: fixed;
	top: 0;
	left: 0;
	background: transparent;
	width: 100%;
	padding: 20px 20px;
	z-index: 10;
	text-align: right;

	.part {
		float: right;

		.langs {
			position: relative;

			.flag {
				width: 34px !important;
				height: auto !important;
				margin: -3px 0 0 0;
				box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
				@include border-radius(3px);
			}

			.lang-current {
				cursor: pointer;
			}
			.lang-switch {
				display: none;
				position: absolute;
				top: 30px;
				left: -10px;
				padding: 10px;
				background: #fff;
				box-shadow: rgba(0,0,0, .2) 0px 5px 20px;
				width: auto;
				text-align: center;

				@include border-radius(4px);

				&.active {
					display: block;
				}

				&:after {
					bottom: 100%;
					left: 15px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(255, 255, 255, 0);
					border-bottom-color: #fff;
					border-width: 6px;
					margin-left: -6px;
				}

				ul {
					margin: 0;
					padding: 0;
					list-style: none;
					li {
						margin: 0 0 10px;
						padding: 0 0 10px;
						border-bottom: 1px solid $outline;

						&:last-child {
							border: none;
							padding: 0;
							margin: 0;
						}

						a {
							display: block;

						}
					}
				}
			}
		}
	}
}

.rows {
	position: relative;
	margin: 0 -10px;
	
	.row {
		margin: 0 0 30px;
		display: table;
		width: 100%;
		table-layout: fixed;
		
		&:last-child {
			margin: 0;
		}
		
		.row-part {
			display: table-cell;
			vertical-align: top;
			padding: 0 10px;
			
			&.align-middle {
				vertical-align: middle;
			}
			&.align-bottom {
				vertical-align: bottom;
			}
			
			&._third {
				width: calc(100% / 3);
			}
			
		}
	}
	
	@include respond-to('1420') {
		.row {
			margin: 0 0 20px;
		}
	}
	
	@include respond-to('991') {
		margin: 0;
		
		.row {
			.row-part {
				padding: 0;
				display: block;
				width: 100%;
				margin: 0 0 20px;
				
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

.menu-open {
	.app-wrapper {
		.app-header {
			display: block;
		}
	}
}

.app-wrapper {
	position: relative;
	height: 100%;
	min-height: calc(100vh - 65px);
	
	.app-header {
		position: fixed;
		top: 0;
		left: 0;
		width: 242px;
		background: #fff;
		border-right: 1px solid $outline;
		margin: 0;
		height: 100%;
		z-index: 101;
		.app-logo {
			display: table;
			width: 100%;

			a {
				height: 64px;
				line-height: 64px;
				text-transform: uppercase;
				font-weight: 900;
				display: table-cell;
				text-align: center;
				vertical-align: middle;
				font-size: 26px;
				letter-spacing: 5px;
				color: $primary;
				
				strong {
					font-weight: 900;
				}

				svg {
					width: 144px;
					height: 64px;
					display: block;
					margin: 0 auto;
					line-height: 64px;
				}
			}

			img {
				max-width: 90%;
				max-height: 90%;
				padding-top: 5%;
			}
		}
		
		.app-nav {
			font-size: 14px;
			letter-spacing: .3px;
			text-transform: uppercase;
			
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					
					a {
						color: $secondary-dark;
						line-height: 26px;
						display: block;
						padding: 14px 20px 14px 60px;
						position: relative;
						
						@include transition(.6s ease background);
						
						&:hover {
							background: $background-light;
						}
						
						.la {
							font-size: 25px;
							position: absolute;
							top: 50%;
							left: 28px;
							margin: -1px 0 0 0;
							
							@include transform(translate(0, -50%));
						}

						&.active {
							background: $background-light;
							font-weight: 500;
						}
					}
				}
			}
		}

		.scrollbar {
			position: relative;
			height: calc(100vh - 64px);
			padding-bottom: 0;
		}
	}

	.app-widgets {
		padding: 0 0 20px;
		.widget {
			margin: 30px 0 0 0;
			padding: 0 28px 0;
			font-size: 14px;
			
			.widgetHead {
				display: table;
				width: 100%;
				table-layout: fixed;
				margin: 0 0 20px;
				
				.part {
					display: table-cell;
					vertical-align: midde;
					padding: 0 10px;
					white-space: nowrap;
					
					&:last-child {
						padding-right: 0;
						text-align: right;
					}
					&:first-child {
						padding-left: 0;
					}
				}
				
				h4 {
					margin: 0;
					line-height: 25px;
					font-size: 14px;
					font-weight: 400;
					color: $secondary-light;
				}
				
				.addMore {
					vertical-align: middle;
					font-size: 23px;
					font-weight: 400;
					color: $primary;
					margin: 0;
					padding: 0;
					position: relative;
					border: none;
				}
			}
			
			.widgetBody {
				padding: 0;
			}
			
			.list {
				margin: 0;
				padding: 0;
				list-style: none;
				letter-spacing: .3px;
				
				li {
					margin: 0 0 4px;
					position: relative;
					
					&:last-child {
						margin: 0;
					}
					
					a {
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						display: block;
						white-space: nowrap;
					}
				}
			}
		}
	}
	
	.app-toolbar {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 100;
		border-bottom: 1px solid $outline;
		height: 65px;
		background: #fff;
		padding: 10px 30px 10px 270px;
		display: table;
		table-layout: fixed;
		
		> .part {
			display: table-cell;
			vertical-align: middle;
			
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				text-align: right;
				padding-right: 0;
			}
		}
		
		.dropdown {
			position: relative;
			text-align: left;
			
			&.active {
				
				> .la {
					color: $primary;
				}
				
				.dropdownContent {
					display: block;
				}
			}
			
			.dropdownContent {
				position: absolute;
				right: -15px;
				top: 50px;
				width: 340px;
				display: none;
				
				&._right {
					right: auto;
					left: -15px;
					
					&:after {
						right: auto;
						left: 27px;
					}
				}
				
				&._user {
					top: 56px;
					right: -9px;
				}
				
				&._small {
					width: auto;
					white-space: nowrap;

					.wrapper {
						padding: 20px;
					}
				}
				
				&:after {
					bottom: 100%;
					right: 20px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(255, 255, 255, 0);
					border-bottom-color: #fff;
					border-width: 8px;
					margin-left: -8px;
				}
				
				.wrapper {
					padding: 10px 0;
				}
				
				.list-nav {
					ul {
						margin: 0;
						padding: 0;
						list-style: none;
						
						li {
							margin: 0 0 20px;
							font-weight: 500;
							font-size: 12px;
							display: block;
							position: relative;
							text-transform: uppercase;
							padding: 0 0 0 35px;
							cursor: pointer;
							
							&:last-child {
								margin: 0;
							}

							.href {
								color: $secondary-dark;

								&:hover {
									color: $primary;
								}
							}
							
							span {
								vertical-align: middle;
								
								@include transition(.6s ease color);
							}
							
							.la {
								@include transform(translate(0, -50%));
								font-size: 24px;
								position: absolute;
								left: 0;
								color: $secondary-dark;
								top: 50%;
							}
							
							
						}
					}
				}
				
				.list-content {
					.media {
						padding: 10px 20px;
						
						@include transition(.6s ease background);
						
						&.user {
							padding: 20px;
							
							.mediaTitle {
								font-weight: 500;
							}
						}
						
						.mediaTitle {
							color: $primary-dark;
							
							@include transition(.6s ease color);
						}
					}
				}
				
				.list-footer {
					overflow: hidden;
					
					@include border-radius(0 0 4px 4px);
					
					a {
						display: block;
						font-weight: 500;
						text-transform: uppercase;
						color: $primary;
						padding: 15px;
						display: block;
						text-align: center;
						background: $background-light;
						
						@include transition(.6s ease color, .6s ease background);
						
						&:hover {
							color: $background-light;
							background: $primary;
						}
					}
				}
			}
		}
		
		.toolbarLeft,
		.toolbarRight {
			display: table;
			width: auto;
			
			> .part {
				display: table-cell;
				vertical-align: middle;
				padding: 0 18px 0 0;

				&.menu-toggle {
					display: none;

					.hamburger {
						@include transition(.6s ease transform);
					}

					&.open {
						.hamburger {
							
							@include transform(rotate(90deg));

							.la {
								color: $primary;
							}
						}
					}
				}
				
				&:last-child {
					padding-right: 0;
				}
			}
		}
		
		.toolbarRight {
			float: right;
		}
		
		.toolbar-part {
			position: relative;
			
			.notify {
				position: absolute;
				width: 11px;
				height: 11px;
				border: 2px solid #fff;
				top: 1px;
				right: -2px;
				
				@include border-radius(50%);
				
				&._red {
					background: $red;
				}
				&._green {
					background: $green;
				}
			}
		}

		.langs {
			position: relative;

			.flag {
				width: 24px !important;
				height: auto !important;
				margin: -3px 0 0 0;
				box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
				@include border-radius(3px);
			}

			.lang-current {
				cursor: pointer;
			}
			.lang-switch {
				display: none;
				position: absolute;
				top: 50px;
				left: 0;
				padding: 10px;
				background: #fff;
				box-shadow: rgba(0,0,0, .05) 0px 5px 20px;
				width: auto;
				text-align: center;

				@include border-radius(4px);

				&.active {
					display: block;
				}

				&:after {
					bottom: 100%;
					left: 15px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(255, 255, 255, 0);
					border-bottom-color: #fff;
					border-width: 6px;
					margin-left: -6px;
				}

				ul {
					margin: 0;
					padding: 0;
					list-style: none;
					li {
						margin: 0 0 10px;
						padding: 0 0 10px;
						border-bottom: 1px solid $outline;

						&:last-child {
							border: none;
							padding: 0;
							margin: 0;
						}

						a {
							display: block;

						}
					}
				}
			}
		}
		
		.site-search {
			position: relative;
			padding-left: 38px;
			font-size: 14px;
			color: $secondary-dark;
			
			[type="submit"] {
				position: absolute;
				border: none;
				background: none;
				padding: 0;
				margin: 0;
				left: 0;
				top: 50%;
				font-size: 24px;
				
				@include transform(translate(0, -50%));
			}
			[type="text"] {
				width: 160px;
				border: none;
				background: none;
			}
		}
		
		.user {
			.avatar {
				width: 36px;
				display: block;
				height: 36px;
				background-color: $background;
				color: #fff;
				text-align: center;
				line-height: 36px;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				color: $primary-dark;
				cursor: pointer;
				
				@include border-radius(50%);
			}
		}
		
		.la {
			font-size: 24px;
			color: $secondary-dark;
			cursor: pointer;
			vertical-align: middle;
			
			@include transition(.6s ease color);
			
			&:hover {
				color: $primary;
			}
		}
		
		h1 {
			font-size: 20px;
			font-weight: 400;
			margin: 0;
			color: $primary-dark;
		}

	}
	
	.app-body {
		min-height: 100vh;
		background: $background-light;
		padding: 65px 0 0 241px;
		
		.app-wrapper {
			padding: 30px;
		}
	}
	
	.app-footer {
		display: table;
		width: 100%;
		table-layout: fixed;
		color: $secondary-light;
		font-size: 14px;
		padding: 0 20px 0 260px;	
		
		.part {
			display: table-cell;
			vertical-align: middle;
			padding: 16px 10px;
			
			&:last-child {
				padding-right: 0;
				text-align: right;
			}
			&:first-child {
				padding-left: 0;
			}
			
			p {
				margin: 0;
			}
			
			.la {
				color: $red;
			}
		}
		
	}
	
	@include respond-to('1680') {
	
		.app-header {
			
			.app-nav {
				font-size: 13px;
				
				ul {
					li {
						a {
							padding: 8px 20px 8px 60px;
						}
					}
				}
			}
		}
		
	}
	
	@include respond-to('1300') {
		
		.app-widgets {
			margin: 15px 0 0 0;
			
			.widget {
				padding: 0 20px;
			}
		}
	
		.app-header {
			position: fixed;
			top: 65px;
			left: 0;
			width: 242px;
			bottom: 0;
			z-index: 100;
			display: none;

			.outside {
				content: '';
				position: absolute;
				left: calc(100% + 1px);
				top: 0;
				bottom: 0;
				width: calc(100vw - 241px);
				background: rgba(255,255,255,.8);
			}
						
			// &:after {
			// 	z-index: -1;
			// 	content: '';
			// 	position: absolute;
			// 	left: 100%;
			// 	top: 0;
			// 	bottom: 0;
			// 	width: 100vw;
			// 	background: rgba(0,0,0,.6);
			// }
			
			.app-logo {
				display: none;
			}
			
			.app-nav {
				ul {
					li {
						a {
							padding: 8px 20px 8px 50px;
							
							.la {
								left: 14px;
							}
						}
					}
				}
			}
		}
		
		.app-toolbar {
			padding: 10px 20px;

			.toolbarLeft,
			.toolbarRight {
				
				> .part {
					&.menu-toggle {
						display: table-cell;
					}
				}
			}
		}
		
		.app-body {
			padding: 65px 0 0 0;

			.app-wrapper {
				padding: 30px 20px;
			}
		}
		
		.app-footer {
			padding: 0 20px;
			font-size: 13px;
		}
	
	}
	
	@include respond-to('767') {
		
		.app-footer {
			padding: 20px;
			font-size: 13px;
			
			.part {
				display: block;
				text-align: center !important;
				padding: 0;
			}
		}
		
		.app-toolbar {
			padding-left: 10px;
			
			.toolbarRight {
				> .part {
					
				}
			}
			
			h1 {
				font-size: 18px;
			}
			
			.toolbarLeft,
			.toolbarRight {
				
				> .part {
					padding: 0 10px;
				}
			}
		}
		
		.app-body {
			.app-wrapper {
				padding: 20px 10px;
			}
		}
	}

	@include respond-to('480') {
		.app-toolbar {
			.toolbarLeft,
			.toolbarRight {
				
				> .part {
					padding: 0 5px;
				}
			}
		}
	}

}

.empty-table {
	display: table;
	width: 100%;
	height: auto;
	overflow: hidden;
	text-align: center;
	
	.wrapper {
		padding: 60px 0;
		display: table-cell;
		vertical-align: middle;
	}
	
	img {
		width: 100%;
		max-width: 460px;
		margin: 0 auto 60px;
	}
	
	p {
		font-size: 18px;
		line-height: 22px;
		margin: 0;
	}
	
	.bttn {
		width: auto !important;
	}

	@include respond-to('1420') {
		p {
			font-size: 16px;
			line-height: 22px;
		}
	}
}

#dashboard {
	.dashboard_header {
		display: table;
		width: 100%;
		table-layout: fixed;

		margin: 0 0 50px;

		> .part {
			display: table-cell;
			vertical-align: middle;
			padding: 0 10px;

			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
				text-align: right;
			}
		}

		h1 {
			margin: 0;
		}

		.perex {
			padding: 6px 0 0 0;
			color: $secondary-dark;
			font-size: 16px;

			p {
				color: $secondary-dark;
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
	@include respond-to('1420') {
		.dashboard_header {
			margin: 0 0 30px;
		}
	}
	@include respond-to('991') {
		.dashboard_header {
			margin: 0 0 20px;
			
			> .part {
				display: block;
				padding: 0;
				margin: 0 0 20px;

				&:last-child {
					margin: 0;
				}
			}

			.bttns {
				display: table;
			}
		}
	}

	@include respond-to('600') {
		.dashboard_header {

			.bttns {
				width: 100% !important;

				.bttn {
					display: table;
					width: 100% !important;
					margin: 0 0 4px;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}
}

#test {
	position: relative;
	height: 100%;
	
	.test-progress {
		margin: 0 0 20px;	
	}

	.test-header,
	.test-footer {
		display: table;
		width: 100%;
		position: absolute;
		padding: 0;
		table-layout: fixed;
		
		> .part {
			padding: 0 10px;
			display: table-cell;
			vertical-align: middle;
			
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}
	
	.test-header {
		position: relative;

		.test-name {
			position: relative;
			display: table;
			padding: 0 30px 0 0;
			color: $secondary-dark;
			font-size: 16+px;
			span {
				color: $primary;
			}
			
			.la {
				position: absolute;
				right: 0;
				top: 50%;
				color: $secondary-light;
				@include transform(translate(0, -50%));
				font-size: 21px;
				cursor: pointer;
			}
		}
		
		.test-time {
			background: #fff;
			padding: 0 10px;
			line-height: 36px;
			margin-left: 10px;
			font-size: 18px;
			font-weight: 600;
			color: $primary-dark;
			border: 1px solid $outline;
			display: table;
			float: right;
			min-width: 0;

			
			@include border-radius(4px);
		}
	}
	
	.test-content {
		display: table;
		height: calc(100vh - 180px);
		width: 100%;
		padding: 0;
		
		> .wrapper {
			display: table-cell;
			vertical-align: middle;
		}
	}
	
	.test-block {
		display: table;
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		padding: 0 0 50px;
		
		.top {
			z-index: 100000;
		}

		.test-answer {
			padding: 80px 0;

			.answer-list {
				width: auto;
				display: table;
				margin: 0 auto;

				> div {
					margin: 0 0 30px;

					&:last-child {
						margin: 0;
					}
				}

				.check {
					label {
						font-size: 20px;
					}
				}
			}
			
			.multi-input {
				> ul {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					width: auto;
					margin: 15px auto;
					padding: 0;
					
					> li {
						display: table-cell;
						vertical-align: middle;
						padding: 3px 3px !important;
						
						&:first-child {
							padding-left: 0;
						}
						&:last-child {
							padding-right: 0;
						}
						
					}
				}
				
				input {
					width: 30px;
					text-align: center;

					&:disabled {
						color: $primary;
					}
				}
				
			}
			
			.simple-input {
				display: table;
				width: auto;
				margin: 0 auto;
				min-width: 320px;
			}
			
			input {
				width: 100%;
				border: none;
				background: none;
				padding: 20px 0;
				font-size: 22px;
				font-weight: 400;
				color: $secondary-dark;
				letter-spacing: 2px;
				border-bottom: 2px solid $secondary-light;
			}
		}
		
		.test-nav {
			> ul {
				display: table;
				width: 100%;
				margin: 0;
				padding: 0;
				list-style: none;
				
				> li {
					display: table-cell;
					vertical-align: middle;
					padding: 0 10px;
					
					&:first-child {
						text-align: left;
						padding-left: 0;
					}
					&:last-child {
						text-align: right;
						padding-right: 0;
					}
					
					.bttn {
						display: inline-block;
						z-index: 2;
					}
					
				}
			}
		}
		
		.test-question {
			text-align: center;
			font-weight: 600;
			font-size: 15px;
			color: $secondary-light;
			margin: 0 0 10px;
		}
		h1 {
			margin: 0;
			font-size: 30px;
			font-weight: 700;
			text-align: center;
			
			span {
				color: $primary;
			}
		}
	}
	
	.test-footer {
		bottom: 0;
		
		.test-controls {
			> ul {
				margin: 0;
				padding: 0;
				list-style: none;
				display: table;
				width: auto;
				> li {
					display: table-cell;
					vertical-align: midde;
					padding: 0 30px 0 0;
					
					&:last-child {
						padding: 0;
					}
				}
			}
			
			.control {
				font-weight: 600;
				font-size: 12px;
				color: $primary-dark;
				
				.la {
					font-size: 14px;
					vertical-align: middle;
				}
				
				.key {
					background: #fff;
					padding: 0px 8px;
					font-size: 11px;
					line-height: 26px;
					font-weight: 600;
					color: $secondary-light;
					text-transform: uppercase;
					border: 1px solid $outline;
					display: inline-block;
					margin-right: 10px;
					
					@include border-radius(4px);
				}
			}
		}
	}

	@include respond-to('1420') {

		.test-block {
			h1 {
				font-size: 22px;
			}
			
		}

		.test-block {
			.test-answer {
				padding: 40px 0;

				.answer-list {
					> div {
						margin: 0 0 20px;

					}
	
					.check {
						label {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	@include respond-to('767') {
		.test-content {
			//height: auto;
		}
		.test-block {
			h1 {
				font-size: 20px;
				line-height: 24px;
			}
			.test-answer {
				.multi-input {
					> ul {
					}
				}
			}
			.test-nav {
				> ul {
					> li {
						display: block;
						padding: 0;
						margin: 0 0 10px;

						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}
	}

	@include respond-to('480') {
		.test-header {
			> .part {
				padding: 0;
				display: block;
				margin: 0 0 15px;

				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

.empty-page {
	display: table;
	width: 100%;
	overflow: hidden;
	text-align: center;
	
	.wrapper {
		padding: 30px;
		display: table-cell;
		vertical-align: middle;
	}
	
	img {
		width: 100%;
		max-width: 460px;
		margin: 0 auto 60px;
	}
	
	p {
		font-size: 18px;
		line-height: 26px;
		margin: 0;
	}
}

.login-form-holder {
	display: table;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	.login-logo {
		svg {
			width: 200px;
			height: 64px;
			display: block;
			margin: 0 auto 30px;
			line-height: 64px;
		}
	}
	
	.wrapper {
		padding: 60px 30px;
		display: table-cell;
		vertical-align: middle;
		
	}
	
	.login-form {
		margin: 0 auto;
		width: 100%;
		position: relative;
		max-width: 550px;
		z-index: 2;
		
		.login-head {
			margin: 0 0 50px;
			text-align: center;
			
			h1 {
				margin: 0;
			}
			p {
				margin: 20px 0 0 0;
				font-size: 17px;
				line-height: 27px;
				
			}
		}
		
		.inner {
			padding: 50px 60px;
			background: #fff;
			position: relative;
			box-shadow: 0 2px 80px 0 rgba(37,38,49,0.05);
		}
		
		.link {
			display: inline-block !important;
		}
		.bubbleBig {
			z-index: -1;
			width: 400px;
			height: 400px;
			background: $primary;
			position: absolute;
			top: -60px;
			right: -180px;
			
			@include transform(rotate(-40deg));
			
			@include border-radius(50%);
			
			&:after {
				content: '';
				position: absolute;
				top: 1px;
				left: 50%;
				bottom: 1px;
				width: 100vw;
				background: $primary;
			}
		}
	}
	
	@include respond-to('1680') {
		
		.login-form {
			.login-head {
				p {
					margin: 10px 0 0 0;
					font-size: 16px;
					line-height: 26px;
				}
			}
		}
		
	}
	
	@include respond-to('1420') {
        
        .wrapper {
        	padding: 40px 20px;
        }
        
        .login-form {
        	.inner {
        		padding: 30px;
        	}
        	.login-head {
        		margin: 0 0 30px;
        		
        		p {
					font-size: 15px;
					line-height: 25px;
				}
        	}
        }
    }
    
    @include respond-to('767') {

		.login-logo {
			svg {
				width: 160px;
				height: 46px;
				display: block;
				margin: 0 auto 20px;
				line-height: 46px;
			}
		}
        .wrapper {
			padding: 20px;
			
			> .inner {
				@include border-radius(0);
			}
        }
	}
	@include respond-to('480') {
        .wrapper {
        	padding: 0;
        }
    }
}

.pageTitle {
	display: none;
	@include respond-to('1300') {
		display: block;
	}
}

.form .customSelect .customSelectWrapper__menu .customSelectWrapper__menu-list .customSelectWrapper__option {
	margin: 0;
	padding: 10px 0;
}

.langs-block {
	&._result {
		.result {
			display: none;

			@media screen and (min-width: 768px) {
				display: table-cell;
				width: 70px;
				padding: 0 20px;
			}
		}
	}
}

.test-nav {
	padding-top: 100px;
}

.bttn-holder {
	.bttn {
		margin-bottom: 2px;
		margin-top: 2px;
		margin-right: 5px;
	}
}

.users-login-list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 250px;
		margin: 10px auto;

		i:first-child {
			font-size: 35px;
		}
	}
}

.wg-shares {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: $primary;
			color: white;
			padding: 10px;
			border-radius: 5px;
			margin-bottom: 5px;

			div {
				color: white;
			}

			.bttn {
				margin-right: 5px;
				line-height: 26px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

.bttn-white {
	color: white;
}