.folding-question {
    position: relative;
    text-align: center;

    .answer {
        padding-top: 30px;
        text-align: center;
    }

    .char {
        display: inline-block;
    }

    .character {
        display: inline-block;
        font-size: 25px;
        line-height: 50px;
        font-weight: bold;
        min-width: 50px;
        min-height: 50px;
        text-align: center;
        margin: 10px;
        border-radius: 5px;
        background: $primary;
        color: white;
        cursor: pointer;
        user-select: none;
        position: relative;
        vertical-align: middle;
        
        &.moving {
            opacity: .5;
        }
    }

    .folding-answer-field {
        font-size: 25px;
        line-height: 50px;
        font-weight: bold;
        min-width: 50px;
        min-height: 50px;
        text-align: center;
        margin: 10px;
        border-radius: 5px;    
        color: $secondary-dark;
        background: $outline;    
        transition: all .5s ease;
        pointer-events: none;
        
        &.focused {
            background: $primary-dark;
            opacity: .5;
        }
    }

    @include respond-to('1420') {
        .answer {
            padding-top: 40px;
        }
        .character {
            font-size: 19px;
            line-height: 40px;
            min-width: 40px;
            min-height: 40px;
            margin: 3px;
        }

        .folding-answer-field {
            font-size: 19px;
            line-height: 40px;
            min-width: 40px;
            min-height: 40px;
            margin: 0;
        }
    }
}