
/*  ---------------------------------------------------
	UI
	---------------------------------------------------  */
    
    .arrows {
        display: table;

        .arrow {
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            margin: 0 3px;
            border: none;
            background: none;
            border: 1px solid $outline;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;
            cursor: pointer;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            &:hover {
                .icon {
                    fill: $primaryHover;
                }
            }

            .icon {
                fill: $primary;
                width: 26px;
                height: auto;
                vertical-align: middle;

            }
        }
    }

	/* --- LISTS --- */

        .items-list {
            margin: 0 0 30px;

            &.inline {
                display: table-cell;
                vertical-align: top;
                padding: 0 10px;

                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }

            &:last-child {
                margin: 0;
            }

            .perex {
                margin: 0 0 20px;

                p {
                    color: $secondary-dark;
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            > ul {
                margin: 0 -5px -10px;
                padding: 0;
                list-style: none;
                font-size: 0;

                > li {
                    display: inline-block;
                    width: calc(100% / 2);
                    padding: 0 5px;
                    margin: 0 0 10px;
                    vertical-align: top;
                }
            }

            .item {
                border: 1px solid $outline;
                display: table;
                width: 100%;
                padding: 20px;
                height: auto;

                @include border-radius(6px);

                .item_header,
                .item_footer {
                    display: table;
                    width: 100%;
                    > .part {
                        display: table-cell;
                        vertical-align: middle;
                        padding: 0 5px;

                        &:last-child {
                            padding-right: 0;
                            text-align: right;
                        }
                        &:first-child {
                            text-align: left;
                            padding-left: 0;
                        }
                    }
                }

                .item_header {
                    padding: 0 0 15px;
                    margin: 0 0 15px;
                    border-bottom: 1px solid $outline;
                    .part-edit {
                        .la {
                            line-height: 22px;
                        }
                    }

                    > .part {
                        &:first-child {
                            width: 100%;
                        }
                    }

                    h3 {
                        font-weight: 700;
                        margin: 0;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .item_footer {
                    padding: 20px 0 0 0;

                    > .part {
                        vertical-align: middle;
                    }
                }
                
                .knobs {
                    > ul {
                        margin: 0 auto;
                    }
                }

                .item-types {
                    margin: 0 0 20px;
                    > ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        > li {
                            margin: 0 0 15px;
                            padding: 0 0 15px;
                            border-bottom: 1px dashed $outline;

                            &:last-child {
                                margin: 0;
                                padding: 0;
                                border: none;
                            }
                        }
                    }

                    .type {
                        .type_title {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 600;
                            margin: 0 0 5px;
                        }
                        .type_desc {
                            color: $secondary-light;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                .item-graphs {

                    .item-graph_title {
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        margin: 0 0 5px;
                        min-height: 42px;
                        font-weight: 600;
                        color: $secondary-dark;
                    }

                    > ul {
                        display: table;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        table-layout: fixed;

                        > li {
                            display: table-cell;
                            vertical-align: top;
                            padding: 0 5px;
                            text-align: center;
                        }
                    }
                }

                .date {
                    font-size: 12px;
                    line-height: 18px;
                    color: $secondary-light;
                }
            }

            .item_new {
                border: 2px dashed $outline;
                display: table;
                width: 100px;
                padding: 10px;
                cursor: pointer;
                height: 100px;

                @include border-radius(6px);
    	    
    	        @include transition(.6s ease border-color);

                &:hover {
                    border-color: $primary;
                }

                .item_inner {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    font-size: 36px;
                    color: $primary;
                }
            }

            @include respond-to('767') {
                &.inline {
                    display: block;
                    padding: 0;
                    margin: 0 0 20px;

                    &:last-child {
                        margin: 0;
                    }
                }

                .item {
                    .item-types {
                        text-align: center;

                        .part-langs {
                            > ul {
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }

            @include respond-to('400') {
                .item {
                    .item-graphs {
                        > ul {
                            > li {
                                display: block;
                                width: 100%;
                                padding: 0;
                                margin: 0 0 20px;

                                &:last-child {
                                    margin: 0;
                                }
                            }
                        }

                        .item-graph_title {
                            min-height: 0;
                        }
                    }
                }
            }
        }

	    .inline-list {
	        display: table;
	        width: auto;
	        margin: 0 0 20px;
	        padding: 0;
	        list-style: none; 
	        
	        &._full {
	            width: 100%;
	            table-layout: fixed;
	        }
	        
	        > li {
	            display: table-cell;
	            vertical-align: top;
	            padding: 0 15px;
	            
	            &:first-child {
	                padding-left: 0;
	            }
	            &:last-child {
	                padding-right: 0;
	            }
	        }
	        
	        @include respond-to('991') {
	            > li {
	                display: block;
	                padding: 0;
	                margin: 0 0 10px;
	                
	                &:last-child {
	                    margin: 0;
	                }
	            }
	        }
	    }
	
	/* --- BUTTONS --- */
    
        .bttns {
            .bttn {
                display: inline-block;
                margin: 0 4px;

                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .qBttn {
            

            .la {
                font-size: 21px !important;
                width: 36px;
                height: 36px;
                line-height: 36px;
                background-color: $outline;
                text-align: center;
                color: $primary-dark;
                font-size: 12px;
                text-transform: uppercase;
            
                @include border-radius(50%);
            }
            
        }
        
	    .bttn {
    	    display: table;
    	    line-height: 46px;
    	    padding: 0 22px;
    	    text-align: center;
    	    text-transform: uppercase;
    	    font-weight: 700;
    	    font-size: 13px;
    	    min-width: 200px;
    	    border: 1px solid transparent;
            cursor: pointer;
            white-space: nowrap;
    	    
    	    @include border-radius(4px);
    	    
    	    @include transition(.6s ease color, .6s ease background, .6s ease border-color);
    	    
    	    &._next,
    	    &._prev {
    	        position: relative;
    	        padding: 0 50px;
    	        
    	        .la {
    	            position: absolute;
    	            top: 50%;
	                @include transform(translate(0, -50%));
	                font-size: 22px;
    	        }
    	    }
    	    
    	    &._next {
    	        .la {
    	            right: 20px;
    	        }
    	    }
    	    &._prev {
    	        color: $secondary-dark;
    	        
    	        &:hover {
    	            color: $primary;
    	        }
    	        
    	        .la {
    	            left: 20px;
    	        }
    	    }
    	    
    	    &._full {
    	        width: 100%;
    	    }
    	    
    	    &._offsetTop {
    	        margin-top: 30px;
    	    }
    	    
    	    &._small {
                line-height: 34px;
                padding: 0 10px;
                font-size: 13px;
                font-weight: 600;
                text-align: center;
                display: inline-block;
                min-width: 0;
                text-transform: none;
                
                &._icon {
                    
                    svg,
                    .la {
                        margin: -2px 4px 0 4px;
                        font-size: 22px;
                        font-weight: 300;
                        position: relative;
                        vertical-align: middle;
                    }
                }
    	    }
    	    
    	    &._center {
    	        margin-left: auto;
    	        margin-right: auto;
    	    }
    	    
    	    &._icon {
    	        text-align: left;
    	        
    	        .la {
    	            margin: -4px 10px 0 0px;
                    font-size: 26px;
                    font-weight: 300;
                    position: relative;
                    vertical-align: middle;
        	    }
    	    }
    	    
    	    &._primary {
    	        color: #fff;
    	        background: $primary;
    	        
    	        &:hover {
    	            background: $primaryHover;
    	        }
    	        
    	        &._outline {
    	            background: none;
    	            color: $primary-dark;
    	            border-color: $primary;
    	            
    	            &:hover {
    	                color: $primary;
    	            }
    	        }
    	    }
    	    
    	    &._silent {
    	        color: $secondary-dark;
    	        background: $outline;
    	        
    	        &:hover {
    	            color: $primary-dark;
    	            background: $outlineHover;
    	        }
    	        
    	        &._outline {
    	            background: none;
    	            color: $primary-dark;
    	            border-color: $outline;
    	            
    	            &:hover {
    	                color: $primary;
    	            }
    	        }
    	    }
    	    
    	    &._green {
    	        color: #fff;
    	        background: $green;
    	        
    	        &:hover {
    	            background: $greenHover;
    	        }
    	        
    	        &._outline {
    	            color: $primary-dark;
    	            border-color: $green;
    	            background: none;
    	            
    	            &:hover {
    	                color: $green;
    	            }
    	       }
    	    }
    	    &._orange {
    	        color: #fff;
    	        background: $orange;
    	        
    	        &:hover {
    	            background: $orangeHover;
    	        }
    	        
    	        &._outline {
    	            color: $primary-dark;
    	            border-color: $orange;
    	            background: none;
    	            
    	            &:hover {
    	                color: $orange;
    	            }
    	       }
    	    }
    	    &._red {
    	        color: #fff;
    	        background: $red;
    	        
    	        &:hover {
    	            background: $redHover;
    	        }
    	        
    	        &._outline {
    	            color: $primary-dark;
    	            border-color: $red;
    	            background: none;
    	            
    	            &:hover {
    	                color: $red;
    	            }
    	       }
    	    }
    	    &._blue {
    	        color: #fff;
    	        background: $blue;
    	        
    	        &:hover {
    	            background: $blueHover;
    	        }
    	        
    	        &._outline {
    	            color: $primary-dark;
    	            border-color: $blue;
    	            background: none;
    	            
    	            &:hover {
    	                color: $blue;
    	            }
    	       }
    	    }
    	    
    	    @include respond-to('1680') {
    	        line-height: 40px;
                padding: 0 15px;
                font-size: 13px;
    	    }
    	    
    	    @include respond-to('1420') {
    	        line-height: 36px;
                padding: 0 12px;
                font-size: 12px;
    	    }
    	}
	
    /* --- TAGS --- */
    
        .groups {
            white-space: normal;
            margin: -2px;
            
            .tag {
                margin: 2px;
            }
        }

        .tag {
            height: auto;
            padding: 8px 10px;
            font-size: 11px;
            font-weight: 600;
            text-align: center;
            display: inline-block;
            margin: 1px;
            margin: 0;
            border: none;
            cursor: pointer;
            
            @include border-radius(4px);
            
            .la {
                font-size: 16px;
                margin: -3px -8px 0px 6px;
                cursor: pointer;
                line-height: 16px;
                vertical-align: middle;
            }
            
            &._primary {
                color: #fff;
                background: $primary;
                
                &:hover {
                    color: #fff;
                    background: $primaryHover;
                }
                
                &._alt {
                    color: $primary;
                    background: $primarySilent;
                }
            }
            
            &._silent {
                color: $primary-dark;
    	        background: $outline;
    	        
    	        &:hover {
    	            color: $primary-dark;
    	            background: $outlineHover;
    	        }
    	        
    	        &._alt {
                    color: $secondary-light;
                    background: $background-light;
                }
            }
            
            &._blue {
                color: $blueSilent;
    	        background: $blue;
    	        
    	        &:hover {
    	            color: $blueSilent;
    	            background: $blueHover;
    	        }
    	        
    	        &._alt {
                    color: $blue;
                    background: $blueSilent;
                }
            }
            
            &._orange {
                color: $orangeSilent;
    	        background: $orange;
    	        
    	        &:hover {
    	            color: $orangeSilent;
    	            background: $orangeHover;
    	        }
    	        
    	        &._alt {
                    color: $orange;
                    background: $orangeSilent;
                }
            }
            
            &._red {
                color: $redSilent;
    	        background: $red;
    	        
    	        &:hover {
    	            color: $redSilent;
    	            background: $redHover;
    	        }
    	        
    	        &._alt {
                    color: $red;
                    background: $redSilent;
                }
            }
            
            &._green {
                color: $greenSilent;
    	        background: $green;
    	        
    	        &:hover {
    	            color: $greenSilent;
    	            background: $greenHover;
    	        }
    	        
    	        &._alt {
                    color: $green;
                    background: $greenSilent;
                }
            }
        }
    
    /* --- PAGINATIONS --- */
    
        .pagination {
        display: table;
        width: auto;
        margin: 0 auto;
        font-size: 14px;
        
        &._alt {
            ul {
                li {
                    
                    &.current {
                        a,
                        .href {
                            color: $primary !important;
                            background: none !important;
                        }
                    }
                    
                    a,
                    .href {
                        border: 1px solid $outline;
                        
                        &:hover {
                            background: none;
                            color: $primary;
                        }
                    }
                }
            }
        }

        .empty {
            opacity: .2;
            pointer-events: none;
        }
        
        > * {
            display: table-cell;
            vertical-align: middle
        }
        
        .nav {
            height: 40px;
            border: none;
            color: $secondary-dark;
            font-size: 14px;
            background: none;
            cursor: pointer;
            position: relative;
            padding: 0;
            
            &.prev {
                padding-left: 30px;

                .la {
                    left: 0;
                }
            }
            
            &.next {
                padding-right: 30px;

                .la {
                    right: 0;
                }
            }

            .la {
                position: absolute;
                font-size: 18px;
                top: 50%;
                @include transform(translate(0, -50%));
            }
        }
        
        ul {
            margin: 0;
            padding: 0 20px;
            list-style: none;
            
            li {
                display: table-cell;
                vertical-align: middle;
                padding: 0 2px;
                &.current {
                    a,
                    .href {
                        color: #fff !important;
                        background: $primary !important;
                    }
                }
                
                a,
                .href {
                    display: block;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    color: $primary-dark;
                    
                    @include border-radius(4px);
                    
                    &:hover {
                        background: $outline;
                    }
                }
            }
        }

        @include respond-to('767') {
            .nav {
                display: none;
            }

            ul {
                padding: 0;
                display: table;
                width: 100%;
                table-layout: fixed;

                > li {
                    display: table-cell;

                    .href {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    
    /* --- PROGRESS BARS --- */
    
        .progress-bar {
            width: 100%;
            height: 6px;
            background: $background;
            margin: 0 0 15px;
            position: relative;
            overflow: hidden;
            min-width: 100px;
            
            @include border-radius(4px);
            
            &:last-child {
                margin: 0;
            }
            
            .runner {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 42%;
                
                @include transition(.3s ease width);
                
                background: $primary;
            }
            
            &._red {
                .runner {
                    background: $red;
                }
            }
            &._green {
                .runner {
                    background: $green;
                }
            }
            &._orange {
                .runner {
                    background: $orange;
                }
            }
        }
    
    /* --- TABLES --- */
        
        .gray-part {
            .table {
                background: $background-light;
                padding: 10px 20px 10px !important;

                @include border-radius(4px);
            }
        }

        .table {
            position: relative;
            margin: 0 0 40px;
            padding: 10px 20px;
            background: #fff;
            
            @include border-radius(4px);
            
            &._full {
                .table-head,
                .table-body {
                    .table-row {
                        table-layout: inherit;
                        
                        .table-col {
                            &._full {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            
            &.grid {
                background: none;
                padding: 0;
                
                .width-check {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 15px !important;
                    width: auto !important;
                }

                .part-title {
                    text-align: center;

                }

                .part-number {
                    text-align: center;
                }
                
                .part-langs {
                    > ul {
                        margin: 0 auto;
                    }
                }
                
                .bttn {
                    text-align: center;
                }
                
                .part-text {
                    &._main-title {
                        font-size: 19px;
                    }
                }
                
                [data-title] {
                    position: relative;
                    
                    &:before {
                        content: attr(data-title);
                        display: block;
                        font-weight: 600;
                        font-size: 13px !important;
                        margin: 0 0 5px;
                        color: $secondary-light;
                    }
                }
                
                .knobs {
                    > ul {
                        margin: 0 auto;
                    }
                }
                
                .grid-edit {
                    top: 0;
                    right: 0;
                    padding: 10px 15px !important;
                    position: absolute;
                    width: auto !important;
                }
                
                .grid-hide {
                    display: none !important;
                }
                
                .table-head {
                    display: none;
                }
                
                .table-footer {
                    background: #fff;
                    padding: 20px;
                    margin: 20px 0 0 0;
                    border: none;
                    border-top: 1px solid $background;
                    
                    @include border-radius(4px);
                    
                    > .part {
                        padding: 0 10px;
                        
                    }
                    
                    .bttn {
                        width: auto;
                    }
                    
                }
                
                .table-options {
                    background: #fff;
                    padding: 20px;
                    margin: 0 0 30px;
                    
                    @include border-radius(4px);
                }
                
                .table-body {
                    margin: 0 -10px -20px !important;
                    white-space: normal;
                    display: flex;
                    flex-wrap: wrap;
                    
                    .table-row {
                        display: inline-block;
                        vertical-align: top;
                        width: calc(100% / 5 - 20px) !important;
                        background: #fff;
                        padding: 40px 20px 30px !important;
                        border: 1px solid $background !important;
                        margin: 0 10px 20px !important;
                        position: relative;
                        
                        @include border-radius(4px);
                        
                        @include transition(.6s ease box-shadow);
                        
                        &:hover {
                            box-shadow: 0 2px 16px 0 rgba(153,155,168,0.12);
                        }
                        
                        .table-col {
                            display: block;
                            text-align: center;
                            padding: 10px 0;
                            
                            &:last-child {
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }
                
                .part-users {
                    ul {
                        margin: 0 auto 20px;
                    }
                }
                
                .lang {
                    margin: 0 auto;
                }
                
                .part-user {
                
                    .part {
                        display: block;
                        width: 100%;
                        text-align: center;
                        padding: 0;
                        
                        &.avatar {
                            .avatar-photo {
                                width: 80px !important;
                                height: 80px !important;
                                line-height: 80px;
                                margin: 0 auto 20px;
                                font-size: 18px;
                                
                                .status {
                                    bottom: 3px;
                                    right: 3px;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                }
            }
            
            &:last-child {
                margin: 0;
            }
            
            &._alt {
                padding: 0;
                background: none;
                
                .table-body {
                    .table-row {
                        padding: 0 20px;
                        margin: 0 0 5px;
                        background: #fff;
                        border: none;
                        
                        @include border-radius(4px);
                        
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
            
            .table-head,
            .table-body {
                
                .table-row {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                    border-bottom: 1px solid $outline;

                    &._isme {
                        border: 1px solid rgba(77,124,254,.5);
                        box-shadow: rgba(77, 124, 254, 0.2) 0 0 30px;
                    }
                    
                    .table-col {
                        display: table-cell;
                        vertical-align: middle;
                        padding: 16px 20px;
                        
                        &:first-child {
                            padding-left: 0;
                            text-align: left;
                        }
                        &:last-child {
                            //padding-right: 0;
                            //text-align: right;
                        }
                        
                        &.width-auto {
                            text-align: center;
                            width: 70px;
                            padding-right: 0;
                        }
                        &.width-check {
                            text-align: center;
                            width: 90px;
                            padding-right: 0;
                            width: 20px;
                            
                            label {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
            
            .table-head {
                color: $secondary-light;
                font-size: 14px;
                

                .part-text {
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                    }
                }
                ._sort {
                    padding-right: 40px;
                    position: relative;
                    cursor: pointer;
                    
                    &:hover {
                        .la {
                            color: $primary;
                        }
                    }
                    
                    .la {
                        position: absolute;
                        right: 0;
                        font-size: 14px;
                        top: 50%;
                        cursor: pointer;

                        @include transform(translate(0, -50%));
                        
                        @include transition(.6s ease color);
                    }
                }
            }
            
            .table-body {
                color: $primary-dark;
                font-size: 14px;
                letter-spacing: 0.3px;
                //white-space: nowrap;
                
                .table-row {
                    &:last-child {
                        border: none;
                    }
                }
            }
            
            .table-footer {
                width: 100%;
                table-layout: fixed;
                display: table;
                border-top: 1px solid $outline;
                
                > .part {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 20px 20px 10px;
                    
                    .bttn {
                        display: inline-block;
                        margin: 2px;
                        
                        &:last-child {
                            margin: 0;
                        }
                    }
                    
                    &:last-child {
                        padding-right: 0;
                        text-align: right;
                        
                        .bttn {
                            display: inline-block;
                        }
                    }
                    &:first-child {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }

            @include respond-to('1680') {
                
                &.grid {
                    .part-text {
                        &._main-title {
                            font-size: 17px;
                        }
                    }
                    .table-body {
                        .table-row {
                            width: calc(100% / 4 - 20px) !important;

                            .table-col {
                                padding: 7px 0;
                            }
                        }
                    }
                }

            }

            @include respond-to('1199') {
                
                &.grid {
                    .table-body {
                        .table-row {
                            width: calc(100% / 3 - 20px) !important;
                        }
                    }
                }

            }

            @include respond-to('991') {
                
                &.mobile-grid {

                    background: none;
                    padding: 0 !important;
                    
                    .word._voice {
                        margin: 0 auto;
                    }

                    .hide-s,
                    .hide-m {
                        display: block !important;
                    }

                    .width-check {
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 15px !important;
                        width: auto !important;
                    }

                    .part-title {
                        text-align: center;
                    }

                    .part-number {
                        text-align: center;
                    }
                    
                    .part-langs {
                        > ul {
                            margin: 0 auto;
                        }
                    }
                    
                    .bttn {
                        text-align: center;
                    }
                    
                    .part-text {
                        &._main-title {
                            font-size: 19px;
                        }
                    }
                    
                    [data-title] {
                        position: relative;
                        
                        &:before {
                            content: attr(data-title);
                            display: block;
                            font-weight: 600;
                            font-size: 13px !important;
                            margin: 0 0 5px;
                            color: $secondary-light;
                        }
                    }
                    
                    .knobs {
                        > ul {
                            margin: 0 auto;
                        }
                    }
                    
                    .grid-edit {
                        top: 0;
                        right: 0;
                        padding: 10px 15px !important;
                        position: absolute;
                        width: auto !important;
                    }
                    
                    .grid-hide {
                        display: none !important;
                    }
                    
                    .table-head {
                        display: none;
                    }
                    
                    .table-footer {
                        background: #fff;
                        padding: 20px;
                        margin: 0;
                        border: none;
                        border-top: 1px solid $background;
                        
                        @include border-radius(4px);
                        
                        > .part {
                            padding: 0 10px;
                            
                        }
                        
                        .bttn {
                            width: auto;
                        }
                        
                    }
                    
                    .table-options {
                        background: #fff;
                        padding: 0 0 20px 0;
                        margin: 0 0 30px;
                        
                        @include border-radius(4px);
                    }
                    
                    .table-body {
                        margin: 0 !important;
                        white-space: normal;
                        display: flex;
                        flex-wrap: wrap;
                        
                        .table-row {
                            display: inline-block;
                            vertical-align: top;
                            background: #fff;
                            padding: 20px !important;
                            border: 1px solid $background !important;
                            margin: 0 10px 20px !important;
                            position: relative;
                            
                            @include border-radius(4px);
                            
                            @include transition(.6s ease box-shadow);
                            
                            &:hover {
                                box-shadow: 0 2px 16px 0 rgba(153,155,168,0.12);
                            }
                            
                            .table-col {
                                display: block;
                                text-align: center;
                                padding: 10px 0;
                                
                                &:last-child {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
                    }
                    
                    .part-users {
                        ul {
                            margin: 0 auto;
                        }
                    }
                    
                    .lang {
                        margin: 0 auto;
                    }
                    
                    .part-user {
                    
                        .part {
                            display: block;
                            width: 100%;
                            text-align: center;
                            padding: 0;
                            
                            &.avatar {
                                .avatar-photo {
                                    width: 80px !important;
                                    height: 80px !important;
                                    line-height: 80px;
                                    margin: 0 auto 20px;
                                    font-size: 18px;
                                    
                                    .status {
                                        bottom: 3px;
                                        right: 3px;
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                        }
                    }

                    .table-body {
                        .table-row {
                            width: calc(100% / 2 - 4px) !important;
                            margin: 0 2px 4px !important;
                        }
                    }
                }

                &.grid {
                    .table-body {
                        .table-row {
                            width: calc(100% / 2 - 4px) !important;
                        }
                    }
                }

                .table-footer {
                    padding: 10px 0 10px;
                    > .part {
                        display: block;
                        padding: 0;
                        margin: 5px 0 0 0;
                        text-align: center !important;
                    }
                }

            }

            @include respond-to('600') {
                &.mobile-grid {
                    .table-body {
                        .table-row {
                            width: 100% !important;
                            margin: 0 0 4px !important;
                        }
                    }
                }
                &.grid {
                    .table-body {
                        .table-row {
                            width: 100% !important;
                        }
                    }
                }
                
                .table-head,
                .table-body {
                    
                    .table-row {
                        
                        .table-col {
                            padding: 16px 10px;
                        }
                    }
                }

            }
        }
        
        .table-options {
            display: table;
            width: 100%;
            table-layout: fixed;
            padding: 10px 0 20px;
            border-bottom: 3px solid $background;
            margin: 0 0 10px;
            
            h2 {
                margin: 0;
                font-size: 19px;
                line-height: 29px;
                font-weight: 400;
                
                span {
                    font-weight: 300;
                    color: $secondary-light;
                }
            }
            
            > .part {
                padding: 0 10px;
                display: table-cell;
                vertical-align: middle;
                
                 &:last-child {
                    padding-right: 0;
                    text-align: right;
                }
                &:first-child {
                    padding-left: 0;
                    text-align: left;
                }
            }
            
            @include respond-to('1300') {
				h2 {
                    font-size: 17px;
                    line-height: 27px;
                }
            }
            
            @include respond-to('767') {
				.part {
                    display: block;
                    padding: 0;
                    margin: 0 0 10px;
                    text-align: center !important;

                    &:last-child {
                        margin: 0;
                    }
                }
			}
        }
        
        .word {
            display: block;
            white-space: normal;

            &._voice {
                padding-right: 30px;
                position: relative;
                display: table;

                .la {
                    position: absolute;
                    font-size: 21px;                    
                    padding: 0;                    
                    width: 24px;                    
                    height: 24px;                    
                    line-height: 24px;                    
                    color: $secondary-dark;   
                    background: $background;                 
                    border-radius: 50%;
                    right: 0;                    
                    text-align: center;
                    top: 50%;
                    @include transform(translate(0, -50%));
                    cursor: pointer;
                }
            }
        }
        
        .part-users {
            ul {
                margin: 0 0 20px;
                padding: 0;
                list-style: none;
                display: table;
                table-layout: fixed;
                
                li {
                    margin: 0 -10px -20px 0;
                    display: inline-block;
                    vertical-align: middle;
                    
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            
            .avatar {
                width: 44px;
                height: 44px;
                line-height: 40px;
                border: 2px solid #fff;
                background-color: $outline;
                text-align: center;
                color: $primary-dark;
                font-weight: 700;
                font-size: 12px;
                text-transform: uppercase;
                
                @include border-radius(50%);
                
                &._more {
                    color: $secondary-light;
                }
            }
        }
        
        .part-check {
            .checkbox {
                label {
                    padding: 0 0 0 20px !important;
                }
            }
        }
        
        .part-title {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
        }
        
        .part-langs {
            > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: table;
                width: auto;
                table-layout: fixed;
                
                > li {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 0 5px;
                    
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }

                    .flag {
                        width: 24px !important;
                        height: auto !important;
                        margin: -3px 0 0 0;
                        box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
                        @include border-radius(3px);
                    }
                }
            }
            
            span {
                box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
                width: 36px !important;
                height: 27px !important;
                
                @include border-radius(4px);
            }
            
            .la {
                color: $secondary-light !important;
                font-size: 22px;
            }
        }
        
        .part-user {
            display: table;
            width: 100%;
            font-weight: 600;
            font-size: 13px;
            line-height: 15px;
            
            &._user-alt {
                font-size: 14x;
                
                .avatar {
                    padding: 0 10px 0 0;
                    width: 50px;
                    
                    .avatar-photo {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
            
            .part {
                display: table-cell;
                vertical-align: middle;
            }
            
            .type {
                color: $secondary-light;
                font-size: 12px;
                font-weight: 600;
            }

            .name {
                white-space: initial;
            }
            
            .avatar {
                padding: 0 10px 0 0;
                width: 46px;

                &._flag {
                    width: 30px;
                }

                .flag {
                    width: 27px !important;
                    height: auto !important;
                    box-shadow: rgba(0, 0, 0, 0.1) 0 0 3px;
                    border-radius: 4px;
                    display: block;
                    vertical-align: middle;
                    margin: -2px 0 0 0;
                }
                
                .avatar-photo {
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    display: block;
                    background-color: $outline;
                    text-align: center;
                    color: $primary-dark;
                    font-weight: 700;
                    font-size: 12px;
                    text-transform: uppercase;
                    position: relative;
                    
                    @include border-radius(50%);
                    
                    .status {
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border: 2px solid #fff;
                        background: $background;
                        right: 0;
                        bottom: 0;
                        
                        @include border-radius(50%);
                        
                        &._online {
                            background: $green;
                        }
                        &._away {
                            background: $orange;
                        }
                        &._offline {
                            background: $red;
                        }
                        &._inactive {
                            background: $secondary-dark;
                        }
                    }
                }
            }

            @include respond-to('1199') {
                &._user-alt {
                    font-size: 16px;
                    
                    .avatar {
                        padding: 0 10px 0 0;
                        width: 50px;
                    }
                }
            }

            @include respond-to('991') {
                &._user-alt {
                    font-size: 14px;

                }
            }

            @include respond-to('767') {
                &._user-alt {
                    
                    .avatar {
                        display: none;
                    }
                }

                .avatar {
                    .flag {
                        width: 23px !important;
                    }
                }
            }
        }
        
        .part-date {
            color: $secondary-light;
            
            .la {
                font-size: 20px;
                margin: -4px 1px 0 0;
                vertical-align: middle;
                line-height: 14px;
            }

            small {
                display: block;
                font-size: 12px;
                font-weight: 600;
            }
        }
        
        .part-list {
            > * {
                margin: 0 2px 2px 0;
            }

            &.part-dates-between {
                .tag {
                    display: block;
                    margin: 0;
                }
            }
        }
        
        .part-number {
            color: $primary;
            font-size: 22px;
            font-weight: 700;
            text-align: left;
            
            small {
                font-size: 14px;
                font-weight: 300;
            }
            
            .detial {
                color: $secondary-light;
                display: block;
                font-weight: 500;
                font-size: 14px;
            }
        }
        
        .part-text {
            
            small {
                font-size: 14px;
                font-weight: 300;
            }
            
            .detial {
                color: $secondary-light;
                display: block;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .part-position {
            .position {
                color: $secondary-light;
                font-size: 17px;
                line-height: 22px;
                width: 30px;
                text-align: right;
            }

            @include respond-to('480') {
                .position {
                    font-size: 15px;
                    line-height: 20px;
                    width: 22px;
                }
            }
        }

        .part-points {
            color: $primary;
            font-size: 19px;
            line-height: 22px;
            font-weight: 700;
            width: 50px;

            strong {
                display: block;
                color: $secondary-light;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
            }

            @include respond-to('1420') {
                font-size: 17px;
                line-height: 20px;

                strong {
                    font-size: 13px;
                    line-height: 17px;
                }
            }
            @include respond-to('767') {
                font-size: 15px;
                line-height: 18px;

                strong {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
        
        .part-edit {
            text-align: center;
            position: relative;
            display: table;
            float: right;
            
            &:hover {
                .la {
                    color: $primary;
                }
                
                .edit-content {
                        display: block;
                }
            }
            
            .la {
                font-size: 30px;
                cursor: pointer;
                
                @include transition(.6s ease color);
            }
            
            .edit-content {
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 2;
                padding: 7px 0;
                width: 120px;
                display: none;
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    
                    li {
                        text-align: center;
                        display: block;
                        font-size: 12px;
                        padding: 3px 10px;
                        cursor: pointer;
                        color: $primary-dark;
                        
                        @include transition(.6s ease color);
                        
                        .href {
                            color: $primary-dark;
                            @include transition(.6s ease color);

                            &:hover {
                                color: $primary;
                            }
                        }

                        
                    }
                }
            }
        }
    
    /* --- KNOB --- */
        
        .knobs {
            > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: table;
                width: auto;
                table-layout: fixed;
                
                > li {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 0 5px;
                    
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
        
        .knob {
            width: 60px;
            height: 60px;
            background: $primarySilent;
            box-shadow: inset rgba(255,255,255,1) 0 0 0 2px;
            @include border-radius(50%);
            
            &._small {
                width: 48px;
                height: 48px;
                
                .CircularProgressbar-text {
                    font-size: 26px;
                }
            }

            &._big {
                width: 90px;
                height: 90px;
                
                .CircularProgressbar-text {
                    font-size: 22px;
                }
            }
            
            .CircularProgressbar-trail {
                stroke: none;
            }
            .CircularProgressbar-path {
                stroke: $primary;
            }
            .CircularProgressbar-text {
                fill: $primary;
                font-weight: 700;
                font-size: 25px;
            }
            
            &._red {
                background: $redSilent;
                
                .CircularProgressbar-path {
                    stroke: $red;
                }
                .CircularProgressbar-text {
                    fill: $red;
                }
            }
            
            &._orange {
                background: $orangeSilent;
                
                .CircularProgressbar-path {
                    stroke: $orange;
                }
                .CircularProgressbar-text {
                    fill: $orange;
                }
            }
            
            &._green {
                background: $greenSilent;
                
                .CircularProgressbar-path {
                    stroke: $green;
                }
                .CircularProgressbar-text {
                    fill: $green;
                }
            }
        }
        
    /* --- FORMS --- */
    
        .input-row {
            display: table;
            width: 100%;
            table-layout: fixed;
            margin: 0 0 26px;
            
            &:last-child {
                margin: 0;
            }
            
            p {
                margin: 0 0 10px;
                font-size: 15px;
                
                &:last-child {
                    margin: 0;
                }
            }
            
            > .part {
                padding: 0 5px;
                display: table-cell;
                vertical-align: top;
                position: relative;
                text-align: left;
                
                &:last-child {
                    text-align: right;
                    padding-right: 0;
                }
                &:first-child {
                    text-align: left;
                    padding-left: 0;
                }
            }
            
            @include respond-to('1420') {
                margin: 0 0 15px;
                
                > .part {
                    padding: 0;
                    margin: 0 0 15px;
                    display: block;
                    
                    &:last-child {
                        margin: 0;
                    }
                    
                    .link {
                        margin: 0 auto;
                        display: table;
                    }
                }
            }
            
            @include respond-to('991') {
                
                p {
                    font-size: 14px;
                }   
            }
        }
        
        .form {
            
            .input-holder {
                position: relative;
                
                .ant-input-suffix {
                    position: absolute;
                    top: 17px;
                    right: 15px;
                    font-size: 18px;
                    cursor: pointer;
                    color: $secondary-dark;
                }
            }
                
            .success {
                input,
                textarea {
                    border-color: $green;
                    background: #effbe7;
                    
                }
            }
            .has-error {
                
                ::placeholder {
                    color: $red !important;
                    opacity: 1;
                }
                
                input,
                textarea {
                    border-color: $red;
                    background: #feeaf3;
                    color: $red !important;
                }
            }
            
            .ant-form-explain {
                font-size: 13px;
                color: $red;
                background: none;
                text-align: left;
                padding: 5px 0 0 0;
                font-weight: 700;

                p {
                    color: $red;
                    font-size: 13px;
                    font-weight: 700;
                }
            }
            
            .q-mark {
                position: absolute;
                right: 10px;
                top: 13px;
            }
            
            label {
                margin: 0 0 8px;
                display: block;
                font-weight: 600;
                text-align: left;
                font-size: 14px;
                
                i {
                    font-size: 18px;
                    padding: 0 0 0 5px;
                    font-style: normal;
                    font-weight: 600;
                    vertical-align: bottom;
                    line-height: 16px;
                    color: $primary;
                }
            }
            
            .customSelect {
                text-align: left;
                
                .flag {
                    width: 20px !important;
                    height: auto !important;
                    margin: -3px 5px 0 0;
                    @include border-radius(3px);
                }

                .customSelectWrapper__control {
                    width: 100%;
                    height: auto;
                    border: 1px solid $outline;
                    color: $primary-dark;
                    padding: 4px 4px;
                    font-size: 14px;
                    font-weight: 600;
                    background: #fff;
                    font-family: 'Muli';
                    box-shadow: none !important;
                    min-height: 50px;
                    cursor: pointer;
                    
                    @include border-radius(4px);
                    
                    &.customSelectWrapper__control--menu-is-open {
                        .customSelectWrapper__indicators {
                            .customSelectWrapper__indicator {
                                @include transform(rotate(180deg));
                            }
                        }
                    }
                    
                    .customSelectWrapper__input {
                        > * {
                            height: 30px;
                        }
                    }
                    
                    .customSelectWrapper__value-container {
                        padding: 0;
                        height: auto;
                        
                        > * {
                            height: auto;
                            margin: 2px;
                            padding: 0;
                            
                            .customSelectWrapper__input {
                                padding-left: 10px;
                            }
                        }
                        
                        .customSelectWrapper__single-value {
                            top: auto;
                            @include transform(translate(0));
                            padding: 0;
                            margin: 0 0 0 10px !important;
                            
                            > div {
                                span {
                                    width: 32px !important;
                                    height: 24px !important;
                                    margin: 0px 10px 2px 0;
                                    box-shadow: rgba(0,0,0,.05) 0 0 10px;
                                    @include border-radius(4px);
                                }
                            }
                            
                        }
                        
                        .customSelectWrapper__multi-value {
                            height: 36px;
                            line-height: 36px;
                            padding: 0 35px 0 20px;
                            min-width: 84px;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            display: inline-block;
                            position: relative;
                            cursor: pointer;
                            
                            @include border-radius(4px);
                            
                            color: #fff;
                            background: $primary;
                            overflow: hidden;
                            
                            @include transition(.6s ease background);
                            
                            .customSelectWrapper__multi-value__remove {
                                position: absolute;
                                right: 0;
                                top: 0;
                                height: 100%;
                                color: #fff;
                                padding: 0 10px;
                                
                                @include border-radius(0);
                                
                                @include transition(.6s ease background);
                                
                                &:hover {
                                    background: none;
                                }
                            }
                            
                            &:hover {
                                color: #fff;
                                background: $primaryHover;
                            }
                            
                            .customSelectWrapper__multi-value__label {
                                font-size: 14px;
                                padding: 0 5px 0 0;
                                color: #fff;
                                
                                @include border-radius(0);
                            }
                        }
                        
                        .customSelectWrapper__placeholder {
                            margin: 0;
                            padding: 0 12px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 100%;
                            color: $primary-dark;
                        }
                    }
                    .customSelectWrapper__indicators {
                        padding:  0 8px 0 0;
                        
                        .customSelectWrapper__indicator-separator {
                            display: none;
                        }
                        .customSelectWrapper__indicator {
                            padding: 0;
                            color: $primary-dark;
                            
                            &.customSelectWrapper__loading-indicator {
                                padding-right: 5px;
                                
                                > * {
                                    background: $primary;
                                }
                            }
                        }
                    }
                }
                .customSelectWrapper__menu {
                    border: none;
                    background: #fff;
                    box-shadow: 0 2px 16px 0 rgba(153,155,168,0.12);
                    z-index: 10;
                    @include border-radius(4px);
                    
                    .customSelectWrapper__menu-list {
                        padding: 20px;
                        
                        .customSelectWrapper__menu-notice {
                            font-size: 14px;
                            padding: 0;
                        }
                        
                        .customSelectWrapper__group {
                            padding: 0 0 20px;
                            
                            .customSelectWrapper__group-heading {
                                padding: 0;
                                margin: 0 0 16px;
                            }
                        }
                        
                        .customSelectWrapper__option {
                            font-weight: 700;
                            font-size: 14px;
                            background: none;
                            cursor: pointer;
                            padding: 0;
                            margin: 0 0 20px;
                            color: $primary-dark;
                            
                            &:last-child {
                                margin: 0;
                            }
                            
                            @include transition(.6s ease color);
                            
                            &:hover {
                                color: $primary;
                            }
                            
                            &.customSelectWrapper__option--is-selected {
                                pointer-events: none;
                                color: $primary;
                            }

                            &.customSelectWrapper__option--is-disabled {
                                 opacity: .3;
                                 pointer-events: none;
                            }
                            
                            > div {
                                span {
                                    width: 32px !important;
                                    height: 24px !important;
                                    margin: -2px 10px 0 0;
                                    box-shadow: rgba(0,0,0,.05) 0 0 10px;
                                    @include border-radius(4px);
                                }
                            }
                        }
                    }
                }
            }
            
            .lost-pass {
                a {
                    font-weight: 600;
                    margin-left: 5px;
                }
            }
            
            .social-login {
                ul {
                    margin: 0 -4px;
                    width: 100%;
                    padding: 0;
                    display: table;
                    list-style: none;
                    table-layout: fixed;
                    
                    li {
                        padding: 0 4px;
                        display: table-cell;
                        vertical-align: middle;
                        
                        a {
                            width: 100%;
                            line-height: 46px;
                            text-transform: uppercase;
                            line-height: 38px;
                            display: block;
                            font-size: 12px;
                            color: #fff;
                            font-weight: 700;
                            
                            @include border-radius(4px);
                            
                            &.fb {
                                background: #35599E;
                                
                                &:hover {
                                    background: #204284;
                                }
                            }
                            &.gp {
                                background: #EA4335;
                                
                                &:hover {
                                    background: #d22a1c;
                                }
                            }
                            &.tw {
                                background: #00ADF3;
                                
                                &:hover {
                                    background: #0092cd;
                                }
                            }
                        }
                    }
                }
            }
            
            .type-selects {
                > ul {
                    display: table;
                    margin: 0 auto;
                    padding: 0;
                    list-style: none;
                    width: 100%;
                    table-layout: fixed;
                    
                    > li {
                        padding: 0 5px;
                        display: table-cell;
                        vertical-align: middle;
                        
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
                
                .type-select {
                    
                    [type="radio"] {
                        position: absolute;
                        opacity: 0;
                        
                        &:checked ~ label {
                            border-color: $primary;
                            color: $primary;
                            background: $blueSilent;
                            
                            img {
                                opacity: 1;
                            }
                        }
                    }
                    
                    label {
                        width: 100%;
                        height: auto;
                        border: 1px solid $outline;
                        padding: 110px 20px 15px;
                        text-align: center;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 13px;
                        position: relative;
                        color: $secondary-light;
                        
                        @include border-radius(4px);
                        
                        @include transition(.6s ease border-color, .6s ease color, .6s ease background);
                        
                        &:hover {
                            border-color: $primary;
                        }
                        
                        img {
                            @include transform(translate(-50%, 0));
                            top: 15px;
                            left: 50%;
                            position: absolute;
                            opacity: .5;
                            
                            @include transition(.6s ease opacity);
                        }
                    }
                }
            }
            
            [type="text"],
            [type="email"],
            [type="password"],
            [type="tel"],
            [type="number"],
            textarea {
                width: 100%;
                height: 50px;
                border: 1px solid $outline;
                color: $primary-dark;
                padding: 0 36px 0 16px;
                font-size: 14px;
                font-weight: 600 !important;
                box-shadow: none !important;
                background: none;
                font-family: 'Muli';
                overflow: hidden;
                text-overflow: ellipsis;
                filter: none !important;
                background: #fff;
                
                @include border-radius(4px);
                
                &:disabled {
                    background: $background-light;
                    color: $secondary-light;
                    cursor: not-allowed;
                }
                
            }
            
            textarea {
                padding: 10px 16px;
                height: 120px;
                resize: none;
            }
            
            .check {
                position: relative;
                
                &.error {
                    color: $red;
                    
                    label {
                        &:before {
                            background: #fde1ee;
                        }
                    }
                }
                &.success {
                    color: $green;
                    
                    label {
                        &:before {
                            background: #e6f9dc !important;
                            color: $green;
                        }
                        &:after {
                            color: $green;
                        }
                    }
                }
                
                .ant-checkbox-wrapper-checked {
                    &:before {
                        background: $primary;
                    }
                    &:after {
                        opacity: 1;
                    }
                }
                
                input {
                    position: absolute;
                    opacity: 0;
                    
                    &:disabled ~ label {
                        cursor: default;
                        color: $secondary-dark;
                    }
                    
                    &:checked ~ label {
                        &:before {
                            background: $primary;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }
                
                label {
                    padding-left: 34px;
                    position: relative;
                    font-size: 14px;
                    margin: 0;
                    font-weight: 600;
                    cursor: pointer;
                    
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        background: $outline;
                        
                        @include transition(.6s ease color, .6s ease background, .6s ease border-color);
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        font-size: 14px;
                        opacity: 0;
                        color: #fff;
                        
                        @include transition(.6s ease color, .6s ease background, .6s ease opacity, .6s ease border-color);
                    }
                }
                
                &.checkbox {
                    label {
                        &:before {
                            @include border-radius(4px);
                        }
                        &:after {
                            font-family: LineAwesome;
                            content: '';
                        }
                    }
                    
                    
                }
                
                &.radio {
                    label {
                        &:before {
                            @include border-radius(50%);
                        }
                        &:after {
                            content: '';
                            width: 8px;
                            height: 8px;
                            background: #fff;
                            top: 0;
                            left: 0;
                            display: table;
                            margin: 6px;

                            @include border-radius(50%);
                        }
                    }
                    
                    &.success {
                        
                        label {
                            &:after {
                                background: $green !important;
                            }
                        }
                    }
                }
                
                &.toggle {
                    
                    &.error {
                        color: $red;
                        
                        label {
                            &:before {
                                background: $red;
                                border-color: $red;
                            }
                            &:after {
                                background: #fff;
                            }
                        }
                    }
                    &.success {
                        color: $green;
                        
                        label {
                            &:before {
                                border-color: $green;
                                background: $green !important;
                            }
                            &:after {
                                background: #fff !important;
                            }
                        }
                    }
                    
                    input {
                        
                        &:checked ~ label {
                            &:before {
                                background: $primary;
                                border-color: $primary;
                            }
                            &:after {
                                left: 22px;
                                background: #fff;
                            }
                        }
                    }
                    
                    label {
                        padding-left: 58px;
                        
                        &:before {
                            top: -1px;
                            width: 40px;
                            background: $background;
                            height: 20px;
                            border: 1px solid $outline;
                            
                            @include border-radius(12px);
                        }
                        &:after {
                            opacity: 1;
                            top: 2px;
                            left: 4px;
                            width: 16px;
                            height: 16px;
                            background: $secondary-light;
                        
                            @include border-radius(50%);      
                            
                            @include transition(.6s ease left, .6s ease background);
                        }
                    }
                }
            }
            
            @include respond-to('1680') {
                [type="text"],
                [type="email"],
                [type="password"],
                [type="tel"],
                [type="number"],
                textarea {
                    font-size: 13px;
                }
            }
            
            @include respond-to('1420') {
                label {
                    margin: 0 0 8px;
                    font-size: 13px;
                }   
                
                .check {
                    label {
                        font-size: 13px;
                    }
                }
            }

            @include respond-to('1300') {
                .customSelect {
                    .customSelectWrapper__control {
                        font-size: 12px;
                        min-height: 40px;;
                    }
                }
            }
            
            @include respond-to('1199') {
                [type="text"],
                [type="email"],
                [type="password"],
                [type="tel"],
                [type="number"],
                textarea {
                    font-size: 12px;
                }
            }
            
            @include respond-to('767') {
                
                .social-login {
                    ul {
                        margin: 0;
                        
                        li {
                            display: block;
                            padding: 0;
                            margin: 0 0 10px;
                            
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
                
            }
            
            @include respond-to('480') {
                .type-selects {
                    > ul {
                        > li {
                            display: block;
                            padding: 0;
                            margin: 0 0 10px;
                            
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
            
        }
        
        .language-selects {
            display: table;
            width: 100%;
            table-layout: fixed;
            padding: 0;
            
            .language-selects-part {
                padding: 0 10px;
                display: table-cell;
                vertical-align: middle;
                
                .bttn {
                    display: inline-block;
                }
                
                &:last-child {
                    padding-right: 0;
	                text-align: right;
	            }
	            &:first-child {
	                text-align: left;
	                padding-left: 0;
	            }
	            
                &._arrow {
                    width: 50px;
                    vertical-align: bottom;
                    text-align: center;
                    color: $secondary-light;
                    
                    .icon {
                        font-size: 30px;
                        margin: 0 0 5px;
                    }
                }
            }

            @include respond-to('480') {
                .language-selects-part {
                    padding: 0;
                    display: block;

                    &._arrow {
                        width: 100%;
                        padding: 10px 0;

                        .icon {
                            font-size: 36px;
                            margin: 0;

                            @include transform(rotate(90deg));
                        }
                    }
                }
            }
        }
        
    /* --- WIDGETS --- */
     
        .isEmpty {
            text-align: center;
            font-size: 22px;
            line-height: 30px;
            padding: 60px 0 60px;
            font-weight: 600;
            color: $secondary-light;
            display: none;
        }

        .small-widgets {
            margin: 0 -10px;
            
            &._alt {
                margin: 0;
                
                > ul {
                    background: #fff;
                    padding: 20px 0;
                    
                    @include border-radius(4px);
                    
                    > li {
                        border-right: 1px solid $outline;
                        padding: 0 40px;
                        
                        &:last-child {
                            border: none;
                        }
                    }
                }
                
                .small-widget {
                    background: none;
                    padding: 0;
                }
            }
            
            > ul {
                display: table;
                width: 100%;
                table-layout: fixed;
                margin: 0;
                padding: 0;
                 
                > li {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 0 10px;
                    height: 100%;
                }
            }
        
            .small-widget {
                padding: 20px;
                background: #fff;
                display: table;
                width: 100%;
                height: 100%;
                position: relative;

                @include border-radius(4px);
                
                > ._part {
                    display: table-cell;
                    vertical-align: middle;
                    
                    &._left {
                        padding-right: 20px;
                    }
                    &._body {
                        width: 100%;
                    }
                    &._right {
                        padding-left: 20px;
                    }
                    
                    &._align-top {
                        vertical-align: top;
                    }
                    &._align-bottom {
                        vertical-align: bottom;
                    }

                    &._after button svg {
                        @include border-radius(4px);
                    }
                }

                .points {
                    .la {
                        font-size: 54px;
                        color: $green;
                    }
                }

                .part-langs {
                    > ul {
                        > li {
                            .flag {
                                width: 32px !important;
                            }
                        }
                    }
                }
                
                .widget-type {
                    color: #fff;
                    width: 60px;
                    height: 60px;
                    text-align: center;
                    line-height: 60px;
                    background: $primary;
                    @include border-radius(50%);
                    font-size: 32px;
                    position: relative;
                    z-index: 2;
                    margin: 8px;
                    
                    &:before {
                        content: '';
                        position: absolute;
                        top: -8px;
                        left: -8px;
                        bottom: -8px;
                        right: -8px;
                        z-index: -1;
                        @include border-radius(50%);
                        background: $primary;
                        opacity: .05;
                    }
                    
                    &._orange {
                        background: $orange;
                        
                        &:before {
                            background: $orange;
                        }
                    }
                    &._green {
                        background: $green;
                        
                        &:before {
                            background: $green;
                        }
                    }
                }
                
                .red-text {
                    color: $red;
                }
                .green-text {
                    color: $green;
                }
                
                small {
                    font-size: 13px;
                }
                
                h3 {
                    font-size: 16px;
                    color: $primary-dark;
                    line-height: 21px;
                    font-weight: 700;
                    margin: 0;
                }
                
                h2 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 22px;
                    margin: 0;
                    display: table;
                    padding: 0 20px 0 0;
                    position: relative;
                    
                    .la {
                        position: absolute;
                        right: 0;
                        font-size: 15px;
                        top: 4px;
                        right: 0;
                    }
                }
                p {
                    color: $secondary-light;
                    font-size: 14px;
                    margin: 0;
                }
            }
            
            @include respond-to('1199') {

                .small-widget {
                    padding: 15px;

                    > ._part {
                        &._left {
                            padding-right: 15px;
                        }
                    }

                    .widget-type {
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 26px;
                    }

                    h3 {
                        margin: 0;
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
            }

            @include respond-to('991') {
                margin: 0 -10px 30px;

                &._alt {

                    .small-widget {
                        margin: 0;

                        > ._part {
                            text-align: center;
                            display: block;
                            width: 100%;
                            
                            &._left {
                                padding: 0;
                                position: relative;
                                left: auto;
                                top: auto;
                                @include transform(translate(0, 0));
                                margin: 0 auto 20px;
                            }

                            .results {
                                margin: 0 auto;
                            }
                        }
                    }
                }

                .small-widget {
                    margin: 33px 0 0 0;
                    padding: 38px 15px 15px;
                                       
                    h2 {
                        padding: 0;
                        margin: 0 auto;
                    }

                    > ul {
                        > li {
                            display: block;
                            width: 100%;
                            padding: 0;
                            margin: 0 0 10px;
    
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }

                    > ._part {
                        text-align: center;
                        
                        &._left {
                            padding: 0;
                            position: absolute;
                            left: 50%;
                            top: -33px;
                            @include transform(translate(-50%, 0));
                        }
                    }

                    .part-langs {
                        > ul {
                            margin: 0 auto;
                        }
                    }
                }
            }

            @include respond-to('600') {
                margin: 0;

                > ul {
                    > li {
                        display: block;
                        width: 100%;
                        padding: 0;
                        margin: 0 0 10px;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }

                &._alt {
                    > ul {
                        > li {
                            border-right: 0;
                            padding: 0;
                            border-bottom: 1px solid $outline;
                            margin: 0 0 30px;
                            padding: 0 0 30px;

                            &:last-child {
                                padding: 0;
                                border: none;
                                margin: 0;
                            }
                        }
                    }
                    .small-widget {
                        padding: 0 20px;

                        > ._part {
                            &._left {
                                display: block;
                            }
                        }
                    }
                }

                .small-widget {
                    height: 80px;
                    margin: 0;
                    padding: 15px;

                    &._keepLeft {
                        padding-top: 90px;

                        > ._part {
                            &._left {
                                top: 18px;
                                display: block;
                            }
                        }
                    }

                    > ._part {
                        &._left {
                            display: none;
                        }
                    }
                }                
            }
        }
     
        .widget-holder-header,
        .widget-holder-body,
        .widget-holder-footer {
            display: table;
            width: 100%;
            table-layout: fixed;
            
            > .widget-part {
                display: table-cell;
                vertical-align: middle;
                padding: 0 10px;
                
                &:last-child {
                    padding-right: 0;
                    text-align: right;
                }
                &:first-child {
                    padding-left: 0;
                    text-align: left;  
                }
            }
            
        }
        
        .widget-holder-body {
            > .widget-part {
                vertical-align: top;
            }

            @include respond-to('1199') {
                > .widget-part {
                    display: block;
                    width: 100%;
                    padding: 0;
                    margin: 0 0 20px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
        
        .widget-holder-header {
            margin: 0 0 20px;

            .arrows {
                float: right;
            }
            
            h2 {
                margin: 0 !important;
                font-size: 18px;
                color: $secondary-dark;
            }
            
            a {
                color:$secondary-dark;
                font-weight: 700;
                font-size: 12px;
                text-transform: uppercase;
                
                &:hover {
                    color: $primary;
                }
            }
        }
     
        .widget {
            background: #fff;
            text-align: left;
            margin: 0 0 10px;

            &:last-child {
                margin: 0;
            }
            
            @include border-radius(4px);
            
            .widget-header,
            .widget-body,
            .widget-footer {
                display: table;
                width: 100%;
                table-layout: fixed;
                padding: 20px;
                
                > ._part {
                    padding: 0 10px;
                    display: table-cell;
                    vertical-align: middle;
                    
                    &:last-child {
                        text-align: right;
                        padding-right: 0;
                    }
                    &:first-child {
                        padding-left: 0;
                        text-align: left;
                    }
                }
            }
            
            .widget-header {
                padding: 20px;
                border-bottom: 1px solid $outline;

                h3 {
                    margin: 0;
                }
            }
            
            .widget-body {
                > ._part {
                    vertical-align: top;

                    > ul {
                        padding: 0;
                        list-style: none;
                        color: $secondary-dark;
        
                        > li {
                            position: relative;
                            padding-left: 16px;
                            margin: 0 0 10px;
        
                            &:last-child {
                                margin: 0;
                            }
        
                            &:before {
                                content: '●';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                line-height: 10px;
                                color: $primary;
                                font-size: 10px;
                            }
                        }
                    }
                }

                .pagination {
                    padding-top: 20px;
                }
            }
            
            .widget-footer {
                border-top: 1px solid $background;

                .part-users ul li {
                    margin: 0;
                }
            }

            .timeline {
                .timeline-entry {
                    .timeline-part {
                        &._text {
                            .wrapper {
                                padding: 0;
                            }
                        }
                    }
                }
            }

            .table {
                padding: 0;
                margin: 0;
            }
            
            p, ol, ul {
                color: $primary-dark;
                font-size: 14px;
                line-height: 24px;
                margin: 0 0 10px;
                
                &:last-child {
                    margin: 0;
                }
            }

            
            
            h2 {
                margin: 0 0 20px;
                font-size: 20px;
                position: relative;
                
                strong {
                    color: $secondary-light;
                    font-weight: 300;
                }
                
                &._icon {
                    padding-left: 30px;
                    color: $primary-dark;

                    .la {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        font-size: 22px;
                         color: $secondary-light;
                        @include transform(translate(0, -50%));
                    }
                }
            }

            @include respond-to('1680') {
                h2 {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            @include respond-to('1420') {
                h2 {
                    &._icon {
                        padding-left: 0;

                        .la {
                            display: none;
                        }
                    }
                }
            }
            @include respond-to('991') {
                h2 {
             
                }
            }
            
        }
        
        .block-link {
            position: relative;
            padding-left: 26px;
            color: $secondary-light;
            font-size: 14px;
            display: inline-block;
            
            a {
                color: $primary;
                white-space: nowrap;
            }

            .href {
                cursor: auto !important;
            }
            
            .la {
                position: absolute;
                left: 0;
                top: 50%;
                font-size: 22px;
                @include transform(translate(0, -50%));
            }

            @include respond-to('767') {
                font-size: 13px;
                padding-left: 0;

                .la {
                    display: none;
                }
            }
        }
        
        .results {
            width: 100px;
            
            > ul {
                display: table;
                width: 100%;
                table-layout: fixed;
                margin: 0;
                padding: 0;
                list-style: none;
                height: 84px;
                
                > li {
                    display: table-cell;
                    vertical-align: bottom;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        width: 4px;
                        height: 100%;
                        top: 0;
                        background: #fafafa;
                        @include transform(translate(-50%, 0));
                    }
                }
            }
            
            .result {
                margin: 0 auto;
                display: table;
                width: 4px;
                height: 80%;
                background: $outline;
                position: relative;
                z-index: 2;
                
                @include border-radius(4px 4px 0 0);
                
                &._green {
                    background: $green;
                }
                &._red {
                    background: $red;
                }
                &._orange {
                    background: $orange;
                }
                &._blue {
                    background: $primary;
                }
            }
        }
        
    /* --- SMALL PARTS --- */
    
        .class-code {
            display: table;
            width: auto;
            margin: 0 auto;
            border: 2px solid $outline;
            
            @include border-radius(4px);
            
            ._part {
                display: table-cell;
                vertical-align: middle;
                padding: 16px;
                white-space: nowrap;
                border-right: 2px solid $outline;
                
                &:last-child {
                    border: none;
                }
            }
            
            .class-code_title {
                color: $secondary-dark;
            }
            
            .class-code_number {
                padding: 16px 40px;
                font-weight: 700;
                font-size: 18px;
                letter-spacing: 2px;
                text-align: center;
            }
            
            .class-code_action {
                .la {
                    font-size: 23px;
                    cursor: pointer;
                }
            }

            @include respond-to('991') {
                width: 100%;
            }

            @include respond-to('600') {
                ._part {
                    display: block;
                    border-right: none;
                    border-bottom: 1px dashed $outline;
                }
            }
        }
    
        .load-more {
            color: $primary;
            font-size: 14px;
            padding: 0 30px;
            display: table;
            margin: 40px auto 0;
            cursor: pointer;
            position: relative;
            font-weight: 700;
            text-transform: uppercase;
            
            &:before {
                content: "\f2e1";
                position: absolute;
                left: 0;
                top: -1px;
                font-size: 21px !important;
                font: normal normal normal 16px/1 "LineAwesome";
            }
        }

        .page-header {
            .part {
                [data-tooltip] {
                    &:after {
                        left: auto;
                        right: 0;
                        @include transform(translate(0, 0));
                    }
                }
            }

            @include respond-to('480') { 
                .part {
                    [data-tooltip] {
                        &:after {
                            left: 50%;
                            right: auto;
                            @include transform(translate(-50%, 0));
                        }
                    }
                }
            }
        }
    
        [data-tooltip] {
            position: relative;
            
            &:hover {
                &:after {
                    opacity: 1;
                    bottom: calc(100% + 10px);
                }
            }
            
            &:after {
                font-family: 'Muli', sans-serif;
                content: attr(data-tooltip);
                position: absolute;
                bottom: calc(100%);
                box-shadow: rgba(0,0,0,.1) 0 0 20px;
                left: 50%;
                background: #fff;
                width: auto;
                max-width: 200px;
                padding: 5px 8px;
                display: block;
                line-height: 17px;
                text-align: center;
                font-size: 12px;
                z-index: 1000;
                font-weight: 600;
                color: #333;
                opacity: 0;
                pointer-events: none;
                
                @include transform(translate(-50%, 0));
                
                @include border-radius(4px);
                
                @include transition(.3s ease bottom, .3s ease opacity);
                
            }
        }
    
        .summary {
            .summary-block {
                margin: 0 0 30px;
                
                &:last-child {
                    margin: 0;
                }
            }
            
            .summary-content {
                padding: 0 0 0 36px;
            }
            
            .tag {
                margin: 0 2px 2px 0;
            }
            
            p {
                color: $secondary-light;
                font-size: 14px;
                margin: 0 0 20px;
                
                &:last-child {
                    margin: 0;
                }
            }
            
            h4 {
                position: relative;
                font-size: 18px;
                margin: 0 0 20px;
                padding: 0 0 0 36px;
                font-weight: 600;
                
                .la {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 26px;
                    color: $secondary-dark;
                }
            }

            @include respond-to('1199') {
	            h4 {
                    font-size: 16px;
                }
            }
            
            @include respond-to('767') {
                .summary-content {
                    padding: 0;
                }
	            h4 {
                    padding: 0;

                    .la {
                        display: none;
                    }
                }

                
            }
            
        }
    
        .q-mark {
            position: relative;
            color: $outline !important;
            cursor: pointer;
            box-shadow: none !important;
            background: none !important;
            z-index: 2;
            
            &:hover {
                .q-content {
                    opacity: 1;
                    margin-bottom: 10px;
                }
            }
            
            .la {
                color: $secondary-light;
                font-size: 24px;
            }
            
            .q-content {
                color: $secondary-dark !important;
                background: #fff;
                font-size: 14px;
                position: absolute;
                bottom: 100%;
                margin: 0 0 20px;
                left: 50%;
                padding: 10px;
                font-size: 12px;
                font-weight: 600;
                min-width: 200px;
                opacity: 0;
                text-align: left;
                pointer-events: none;
                @include transform(translate(-50%, 0));
                
                @include transition(.3s ease opacity, .3s ease margin);
                
                &:before {
                    top: 100%;
                	left: 50%;
                	border: solid transparent;
                	content: " ";
                	height: 0;
                	width: 0;
                	position: absolute;
                	pointer-events: none;
                	border-color: rgba(255, 255, 255, 0);
                	border-top-color: #fff;
                	border-width: 6px;
                	margin-left: -6px;
                }
            }
        }
    
        .headline-divider {
            position: relative;
            padding: 0 0 15px 0;
            margin: 0 auto 30px;
            font-size: 16px;
            font-weight: 600;
            position: relative;
            border-bottom: 1px solid $outline;
            
        }
    
        .note {
            font-size: 14px !important;
            color: $secondary-dark;
            padding: 0 0 0 20px;
            margin: 0 0 20px !important;
            border-left: 2px solid $primary;
        }
    
        .divider {
            position: relative;
            padding: 0 30px;
            margin: 40px auto;
            
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                height: 1px;
                width: 100%;
                left: 0;
                background: $outline;
                @include transform(translate(0, -50%));
            }
            
            span {
                position: relative;
                z-index: 2;
                color: $secondary-light;
        		font-size: 14px;
        		line-height: 24px;
        		padding: 0 30px;
        		margin: 40px auto 20px;
        		display: table;
        		background: #fff;
            }
        }
        
        .stepper {
            .stepper-nav {
                margin: 0 -20px 50px;
                
                > ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                    text-align: center;
                    
                    > li {
                        display: table-cell;
                        vertical-align: top;
                        padding: 0 20px;
                        
                        &:last-child {
                            .step {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                
                .step {
                    position: relative;
                    
                    &:after {
                        content: '';
                        position: absolute;
                        right: -24px;
                        top: 27px;
                        width: 4px;
                        height: 4px;
                        border: 2px solid $secondary-dark;
                        opacity: .5;
                        @include border-radius(50%);
                        
                    }

                    &.finished {
                        &:after {
                            border: 2px solid $primary;
                            opacity: 1;
                        }
                    }
                    
                    &.finished,
                    &.current {
                        .step-num {
                            color: #fff;
                            background: $primary;
                        }
                    }
                    
                    .step-num {
                        font-weight: 700;
                        background: #fff;
                        font-size: 13px;
                        color: $primary-dark;
                        padding: 20px 10px;
                        margin: 0 0 10px;
                        box-shadow: rgba(0,0,0,.1) 0 0 20px;
                        @include border-radius(4px);
                        
                        .la {
                            font-weight: 700;
                        }
                    }
                    
                    .step-title {
                        font-weight: 700;
                        font-size: 12px;
                        color: $secondary-dark;
                    }
                }
            }

            @include respond-to('480') {
				.stepper-nav {
                    margin: 0 -5px 30px;

                    .step {
                        .step-title {
                            display: none;
                        }
                        .step-num {
                            margin: 0;
                            padding: 10px;
                        }
                    }

                    > ul {
                        > li {
                            padding: 0 5px;
                        }
                    }
                }
			}
        }
        
        .add-words {
            padding: 0;
            position: relative;
            
            .removePart,
            .addPart {
                width: 50px;
                position: absolute;
                right: 0;
                top: 0;
                line-height: 50px;
                text-align: center;
                font-size: 24px;
                cursor: pointer;
                
                svg {
                    color: $red;
                    margin: 12px 0 0 0;
                }
            }
            
            .addPart {
                svg {
                    color: $primary;
                }
            }
            
            .wordToTranslate {
                display: table;
                width: 100%;
                table-layout: fixed;
                position: relative;
                > .part {
                    display: table-cell;
                    vertical-align: top;
                    padding: 0 5px;
                    position: relative;
                    
                    &:last-child {
                        padding-right: 0;

                        .flag {
                            left: 20px;
                        }
                    }
                    
                    &:first-child {
                        padding-left: 0;
                    }
                }
                
                input {
                    padding: 0 20px 0 60px;
                }

                .helpers {
                    ul {
                        list-style: none;
                        padding-left: 40px;
                        margin: 0;

                        li span {
                            font-weight: bold;
                            color: $primary;
                            padding: 5px 20px;
                            display: inline-block;
                            font-size: 13px;
                            vertical-align:top;
                            line-height: 18px;
                            cursor: pointer;
                            
                            svg {
                                font-size: 13px;
                                vertical-align: middle;
                                transform: translateY(-2px);
                                transition: all .5s ease;
                            }

                            &:hover {
                                svg {
                                    transform: translate(5px, -2px);
                                }
                            }
                        }
                    }
                }
                
                .flag {
                    width: 32px !important;
                    height: 24px !important;
                    margin: -2px 10px 0 0;
                    box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
                    border-radius: 4px;
                    position: absolute !important;
                    top: 15px !important;
                    left: 15px;
                }
            }
            
            .add-words-row {
                position: relative;
                margin: 0 0 10px;
                padding: 0 50px 0 0;
                
                &:last-child {
                    margin: 0;
                }
            }
            
            @include respond-to('767') {

                .add-words-row {
                    margin: 0 0 20px;

                    &:last-child {
                        margin: 0;
                    }
                }
                
				.wordToTranslate {
                    > .part {
                        display: block;
                        padding: 0;
                        margin: 0 0 5px;;

                        &:last-child {
                            margin: 0;

                            .flag {
                                left: 15px;
                            }
                        }
                    }
                }
			}
        }
        
        .add-more {
            margin: 20px 0 0 0;
            @include border-radius(4px);
            padding: 0 10px;
            line-height: 56px;
            display: block;
            cursor: pointer;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            color: $secondary-dark;
            background: #fff;
            border: 1px dashed $outline;
            
            @include transition(.6s ease border-color, .6s ease color);
            
            &:hover {
                color: $primary;
                border-color: $primary;
            }
        }
        
        .langs {
            .langs-block {
                margin: 0 0 5px;
                display: table;
                width: 100%;
                table-layout: fixed;
                
                &:last-child {
                    margin: 0;
                }
                
                .langs-part {
                    display: table-cell;
                    padding: 0 14px;
                    vertical-align: middle;
                    position: relative;
                    
                    &:first-child {
                        
                        padding-left: 0;
                        
                        &:after {
                            content: "\f274";
                            font: normal normal normal 16px/1 "LineAwesome";
                            position: absolute;
                            right: -9px;
                            top: 50%;
                            font-size: 18px;
                            color: $secondary-dark;

                            @include transform(translate(0, -50%));
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
            
            .lang {
                width: 100%;
                font-size: 13px;
                font-weight: 800;

                span {
                    width: 24px !important;
                    height: 18px !important;
                }

                .flag {
                    width: 20px !important;
                    height: auto !important;
                    margin: 0 auto;
                    display: table !important;
                    @include border-radius(3px);
                }
                
                .part {
                    padding: 11px 10px;
                    position: relative;
                    
                    &:first-child {
                        padding: 7px 10px;
                        width: 44px;
                        background: #fff;
                        
                        
                    }
                }
            }

            @include respond-to('767') {

                .langs-block {
                    margin: 0 0 30px;
                    
                    .langs-part {
                        display: block;
                        padding: 0;
                        margin: 0;
                        
                        &:first-child {
                            &:after {
                                display: none;
                            }

                            .lang {
                                @include border-radius(4px 4px 0 0);
                                border-bottom: none;
                            }
                        }

                        &:last-child {
                            margin: 0;

                            .lang {
                                @include border-radius(0 0 4px 4px);
                            }
                        }
                    }
                }

            }
        }
        
        .lang {
            @include border-radius(4px);
            border: 1px solid #E8ECEF;
            display: table;
            font-weight: 700;
            font-size: 12px;
            overflow: hidden;
            
            .part {
                display: table-cell;
                vertical-align: middle;
                padding: 10px 20px;
                border-right:  1px solid #E8ECEF;
                
                &:first-child {
                    padding: 10px;
                    width: 54px;
                    background: #fff;
                }
                
                &:last-child {
                    border: none;
                }
            }
            
            span {
                box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
                width: 33px !important;
                height: 26px !important;
                border-radius: 4px;
            }

            @include respond-to('767') {
               .part {
                    padding: 10px;
               }
            }
        }