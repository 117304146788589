/*  ---------------------------------------------------
	LESS MIXIN
	---------------------------------------------------  */

	@import "_mixin.scss";
	
/*  ---------------------------------------------------
	LESS FONTS
	---------------------------------------------------  */

	@import "_fonts.scss";
	
/*  ---------------------------------------------------
	LESS VARIABLES
	---------------------------------------------------  */

	@import "_variables.scss";

/*  ---------------------------------------------------
	ANIMATIONS
	---------------------------------------------------  */

	@import "_animations.scss";
	
/*  ---------------------------------------------------
	TYPOGRAPHY
	---------------------------------------------------  */

	@import "_typography.scss";
	
/*  ---------------------------------------------------
	UI
	---------------------------------------------------  */

	@import "_ui.scss";

/*  ---------------------------------------------------
	Folding
	---------------------------------------------------  */

	@import "_folding.scss";

/*  ---------------------------------------------------
	DEFAULTS
	---------------------------------------------------  */

	* {
		@include box-sizing(border-box);
		-webkit-tap-highlight-color: transparent;
	}

	:focus { outline: none !important; }
	::-moz-focus-inner {border:0 !important;}

	#root {
		overflow: hidden;
	}

	body {
	    font-family: 'Muli', sans-serif;
	    font-size: 14px;
	    line-height: 20px;
	    color: $primary-dark;
		margin: 0;
			    
	    @include box-sizing(border-box);
	    
	    @include respond-to('1420') {
	    	font-size: 13px;
	    	line-height: 18px;
	    }
	}
	
	a,
	.href {
		color: $primary;
		font-weight: 600;
		text-decoration: none !important;
		
		@include transition(.6s ease color, .6s ease background, .6s ease border-color);
	}
	
/*  ---------------------------------------------------
	HELPERS
	---------------------------------------------------  */
	
	.cover {
		@include background-size(cover !important); 
		
		background-position: center center !important;
	}
	
	._shadow {
		background: #fff;
		box-shadow: 0 2px 16px 0 rgba(153,155,168,0.12);
	}
	._radius {
		@include border-radius(4px);
	}

	.margin-center {
		margin: 0 auto !important;
	}
	
	.text-center {
		text-align: center !important;
	}
	.text-right {
		text-align: right !important;
		
		.bttn {
			display: inline-block;
		}
	}
	.text-left {
		text-align: left;
	}
	
	a.media {
		&:hover {
			background: $background-light;
			
			.mediaTitle {
				color: $primary !important;
			}
		}
	}
	
	.media {
		display: table;
		width: 100%;
		
		.media-left,
		.media-body,
		.media-right {
			display: table-cell;
			
			&.media-top {
				vertical-align: top;
			}
			&.media-middle {
				vertical-align: middle;
			}
			&.media-bottom {
				vertical-align: bottom;
			}
		}
		
		.media-left {
			.circle {
				width: 36px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				font-size: 12px;
				font-weight: 500;
				color: $primary-dark;
				background-color:  $background;
				@include border-radius(50%);
				
				&._woman {
					color: $red;
					background: $redSilent;
				}
				&._man {
					color: $primary;
					background: $primarySilent;
				}
			}
		}
		.media-right {
			padding-left: 14px;
		}
		.media-body {
			width: 100%;
			padding-left: 14px;
			
			p {
				margin: 0;
			}
			
			.mediaTitle {
				font-weight: 500;
				color: $primary-dark;
			}
			.mediaContent {
				color: $secondary-light;
				letter-spacing: .2px;
				font-size: 13px;
			}
		}
	}
	
	._bgLight {
		background:  $background-light;
	}
	
	.hide-m {
		@include respond-to('1199') {
			display: none !important;
		}
	}
	.hide-s {
		@include respond-to('991') {
			display: none !important;
		}	
	}

	.n-p {
		padding: 0 !important;
	}

	.n-p-m {
		@include respond-to('767') {
			padding: 0 !important;
		}
	}