
	$primary: 			#4D7CFE;
	$primaryHover:		#5461e5;
	$primarySilent:		#eff3ff;
	
	$primary-dark: 		#252631;
	
	
	$secondary-dark:	#778CA2;
	$secondary-light:	#98A9BC;
	
	$orange:			#FFAB2B;
	$orangeHover:		#df9c26;
	$orangeSilent:		#fef9eb;
	
	$green:				#6DD230;
	$greenHover: 		#62c229;
	$greenSilent:		#f2fcec;
	
	$red: 				#F54029;
	$redHover:			#d92f1a;
	$redSilent:			#fff3f2;
	
	$blue: 				#2CE5F6;
	$blueHover:			#49ccdd;
	$blueSilent:		#eefcff;
	
	$outline: 			#E8ECEF;
	$outlineHover:		#f0f2f4;
	
	$background:		#F2F4F6;
	$background-light:	#F8FAFB;

/*  ---------------------------------------------------
	BREAKPOINTS
	---------------------------------------------------  */

	$breakpoints: (
		'400':	( max-width:  400px ),
		'480':	( max-width:  480px ),
		'600':	( max-width:  600px ),
		'767':	( max-width:  767px ),
		'991':	( max-width:  991px ),
		'1199': ( max-width:  1199px ),
		'1300': ( max-width:  1300px ),
		'1420': ( max-width:  1420px ),
		'1680': ( max-width:  1680px )
	) !default;
	
	@mixin respond-to($breakpoint) {
		
		@if map-has-key($breakpoints, $breakpoint) {
			
			@media #{inspect(map-get($breakpoints, $breakpoint))} {
			  @content;
			}
		}
		
		@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		    + "Available breakpoints are: #{map-keys($breakpoints)}.";
		}
	}